import React from "react";
import "./landingPageTopSection.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function LandingPageTopSection() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirect = (path) => {
    navigate(path)
  }
  return (
    <div className="LandingPageTopSectionContainer">
      <div className="firstPart">
        <h2>{t("firstCardTitle")}</h2>
        <p>{t("firstCardP")}</p>
        <button className="largeButton" onClick={() => redirect("/corporate-solutions")} >{t("firstCardTopButton")}</button>
        <button className="largeButton" onClick={() => redirect("/solutions-for-individuals")} >{t("firstCardBottomButton")}</button>
      </div>

      <div className="secPart smallCard">
        <h2>{t("secondCardTitle")}</h2>
        <p>{t("secondCardp")}</p>
        <button className="largeButton" onClick={() => redirect("/corporate-solutions/insurance/your-travel")}>{t("moreInfo")}</button>
      </div>

      <div className="thirdPart smallCard">
        <h2>{t("thirdCardTitle")}</h2>
        <p>{t("thirdCardp")}</p>
        <button className="largeButton" onClick={() => redirect("/corporate-solutions/insurance/your-vehicle/")}>{t("moreInfo")}</button>
      </div>
    </div>
  );
}

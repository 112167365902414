import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/outline'
import "./ourBrands.css"
export default function OurBrands() {
  const { t } = useTranslation();
  const brands = [
    "/brands/assistance/",
    "/brands/warranty/"
  ]

  return (
    <div className="block">
      <h1 className="underLinedTitle">{t("ourBrand.title")}</h1>
      <div className="ourBrands">

        <div className="brandCard">
          <h3>{t("ourBrand.firstCard.title")}</h3>
          <p>{t("ourBrand.firstCard.description")}</p>
          <Link to={brands[0]} > {t("ourBrand.knowThem")} <ChevronRightIcon className="w-4 ml-1 cursor-pointer" /></Link>
        </div>

        <div className="brandCard">
          <h3>{t("ourBrand.secCard.title")}</h3>
          <p>{t("ourBrand.secCard.description")}</p>
          <Link to={brands[1]} > {t("ourBrand.knowThem")} <ChevronRightIcon className="w-4 ml-1 cursor-pointer" /></Link>
        </div>

      </div>
    </div>
  );
}

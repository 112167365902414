import React from 'react'
import "./checkedItems.css"
import CheckedItem from './CheckedItem'
export default function CheckedItems({data = []}) {
  return (
    <div className='checkedItems'>
        {data.map(elm=> (
            <CheckedItem {...elm} />
        ))}
    </div>
  )
}

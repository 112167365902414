import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en";
import { TRANSLATIONS_ES } from "./es";
import { TRANSLATIONS_FR } from "./fr";
import { TRANSLATIONS_AR } from "./ar";


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      ar: {
        translation: TRANSLATIONS_AR,
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

i18n.changeLanguage(localStorage.getItem("lng") || "fr");

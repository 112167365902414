export const TRANSLATIONS_ES = {
  about: {
    title: "Sobre nosotras",
    nav: {
      item0: "Quiénes Somos",
      item1: "Historia",
      item2: "Magnitudes básicas",
    },
  },
  solutions: {
    title: "Soluciones",
    nav: {
      item0: "Soluciones Corporativas",
      item1: "Soluciones para Particulares",
    },
  },
  brand: {
    title: "Nuestros compañeros",
  },
  innovation: {
    title: "Noticias",
  },
  findUs: {
    title: "Contacta con nosotros",
  },

  // page home
  firstCardTitle: "Asistencia y servicios",
  firstCardP:
    "Protegemos a más de 100 millones de personas en todo el mundo, garantizando lo que más importa, su tranquilidad y la de los suyos.",
  firstCardTopButton: "Soluciones Corporativas",
  firstCardBottomButton: "Soluciones para Particulares",

  secondCardTitle: "Seguro de viaje",
  secondCardp:
    "El mejor seguro de \n viaje con la red de asistencia al viajero más grande del \n mundo.",

  thirdCardTitle: "Asistencia en carretera",
  thirdCardp:
    "Protegemos las inversiones de las personas en vehículos con programas de \n garantía, protección de  créditos, GAP y otras coberturas.",

  // mid cards

  midSectionCardFirstCardTitle: "Soluciones Corporativas",
  midSectionCardFirstCardp:
    "Productos y servicios que permiten a nuestros partners aumentar su negocio y generar beneficios y protección para sus clientes.",
  midSectionCardFirstCardLink: "Corporativos",

  midSectionCardSecondCardTitle: "Soluciones para Particulares",
  midSectionCardSecondCardp:
    "Estamos cerca de ti, atendiendo tus necesidades y las de tu familia en cualquier parte del mundo. Nuestra misión, garantizar tu tranquilidad para que no te tengas que preocupar de nada.",
  midSectionCardSecondCardLink: "Particulares",

  midSectionCardThirdCardTitle: "Contacta con nosotros",
  midSectionCardThirdCardp:
    "Presencia directa en 33 países, 5 continentes, que garantizan la excelencia operativa en cualquier parte del mundo.",
  midSectionCardThirdCardLink: "Contacta con nosotros",

  //home page last section

  homePageLastSectionTitle: "Protección del estilo de vida",
  homePageLastSectionParagraph:
    "Garantía para dispositivos electrónicos y electrodomésticos. \nProtección de créditos y compras y otros seguros que aportan tranquilidad a las personas.",

  //general ,

  moreInfo: "Más información",

  ourHistoryPage: {
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Historia",
    },
    title: "Historia",
  },
  footer: {
    title: "Más información",
    subTitleOne: "Copyright Afrique assistance",
    subTitleTwo: "Política de Privacidad",
    subTitleThree: "Política de Cookies",
    subTitleFour: "Configurar cookies",

    copyRight: "Afrique assistance © 2022 Todos los derechos reservados",
  },
  figures: {
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Magnitudes básicas",
    },
    title: "Principales Magnitudes",
  },
  aboutPage: {
    title: "MAPFRE Asistencia",
    secondTitle: "A global insurance, reinsurance \n and services company",
    firstParagraph: `MAPFRE Asistencia es una <strong >compañía global de seguros, reaseguro y servicios </strong> 
        fundada en Madrid (España) en 1989 que opera en todo el mundo. Contamos con presencia directa en 
        <strong >27 países, </strong> más de <strong > 1.300 socios corporativos y más de 100 millones de personas que se benefician de nuestros servicios.</strong>`,
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Quienes somos",
    },
    firstBlock: {
      title: "MAPFRE Asistencia",
      p1: `Ofrecemos productos que incrementan la rentabilidad de nuestros socios y mejoran la calidad de vida de las personas. Somos especialistas en soluciones globales de Asistencia y Protección del Automóvil y del Estilo de Vida:`,
      p2: `Con nuestra red de <strong>Asistencia </strong> cuidamos de las personas y los bienes en cualquier parte del mundo. Proporcionamos asistencia ante imprevistos en carretera y en el hogar, así como asistencia sanitaria y en viaje y otros servicios orientados a la mejora de la calidad de vida.`,
      p3: `Protegemos las inversiones de las personas en vehículos con  <strong>programas de garantía</strong> y extensión de garantía, de protección de créditos, PPI, GAP y <strong>otras coberturas para el automóvil.</strong>  `,
      p4: `Nuestros productos  <strong>Lifestyle</strong> proporcionan tranquilidad protegiendo otros bienes de uso cotidiano como electrodomésticos, aparatos informáticos, teléfonos o dispositivos móviles y tarjetas de crédito.`,
      p5: `Somos líderes en la provisión de soluciones para los sectores: seguros, incluyendo soluciones específicas para brokers, finanzas, automoción y viajes y turismo. También diseñamos productos a medida para compañías de otros sectores así como para grandes colectivos.`,
      p6: `Algunos de nuestros servicios y productos están disponibles para su adquisición directa por particulares.`,
    },
    secondBlock: {
      title: "Premios y Certificaciones",
      p1: `Hemos sido reconocidos por nuestra excelencia, entre otros, con el Premio ITIJ 2005, galardón que nos certifica como la Mejor Compañía Internacional de Asistencia y el Premio ITIJ 2011 y 2013 como Mejor Compañía aseguradora. Además contamos con la certificación internacional de calidad ISO 9001.`,
    },
  },
  innovationPage: {
    title: "Noticias",
    secondTitle: "Elemento esencial para MAPFRE Asistencia",
    firstParagraph:
      "“El movimiento innovador es consubstancial a nuestra entidad en todas sus facetas y procesos, y hace que construyamos equipos que, como  un laboratorio, funcionan a toda máquina, con especialistas preparados para hacerse cargo de proyectos en tiempos imposibles para otros,  con personas capaces de extraer más sinergias y más rápido que la mayoría y con grupos de trabajo que ofrecen soluciones creativas.”",
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Noticias",
    },
  },
  brandsPage: {
    title: "Nuestros compañeros",
    secondTitle: "MAPFRE Assistance y MAPFRE Warranty",
    firstParagraph:
      " En cualquier caso nuestros productos y servicios amplían y complementan la propuesta comercial de nuestros partners y pueden ser comercializados y distribuidos bajo su propia marca.",
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Nuestros compañeros",
    },
  },
  corporate: {
    title: "Soluciones  \nCorporativas",
    secondTitle:
      "Más de 30 años incrementando la rentabilidad \nde nuestros socios",
    firstParagraph: `Ofrecemos una gama de productos y servicios de protección a las personas y a los bienes que cubre cada vez más necesidades. La <strong>incorporación o la distribución </strong> de estos servicios, productos y coberturas por parte de nuestros partners los ayuda a <strong> incrementar su negocio, mejorar su imagen de marca, y fidelizar</strong> a sus clientes.`,
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Soluciones Corporativas",
    },
    firstBlock: {
      title: "Soluciones",
      item0: {
        title: "Soluciones para la industria de los Seguros",
        description: `Disponemos de una red de asistencia y prestación de servicios a medida a carteras de asegurados propia en los 5 continentes.
        A través de nuestra faceta de underwriters ofrecemos también coberturas aseguradoras para protección del automóvil y del estilo de vida.`,
      },
      item1: {
        title: "Soluciones para la industria del automóvil",
        description: `Somos especialistas en el diseño y el soporte a la comercialización y distribución de programas de garantía mecánica y protección para vehículos nuevos y usados.`,
      },
      item2: {
        title: "Soluciones para la industria de Viajes y Turismo",
        description: `Ofrecemos seguros de viaje y programas de asistencia al viajero para su distribución offline u online.
        Galardonada en 2011 y 2013 con el Premio ITIJ a la Mejor Compañía de Seguros de Viaje y Miembro Afiliado de la Organización Mundial del Turismo (OMT).`,
      },
      item3: {
        title: "Soluciones para la industria de las Finanzas",
        description: `Desarrollamos programas para incrementar la venta y activación de productos financieros.
        Disponemos también de coberturas aseguradoras para las tarjetas de crédito y las compras financiadas.`,
      },
      item4: {
        title: "Garantía del teléfono móvil",
        description: `La Garantía de Rotura Accidental de Smartphone es un servicio que cubre y corre con los gastos de reparación o sustitución del dispositivo en caso de Daños causados por caídas o golpes`,
      },
      item5: {
        title: "Garantía marrón y blanca",
        description: `La Extensión de Garantía Brown White es un servicio que cubre y apoya
        corre con los gastos de reparación o sustitución del dispositivo en caso de avería
        ocurrido después de la expiración de la garantía del fabricante`,
      },

      moreInfo: "Más información",
    },
    secondBlock: {
      title: "Y además…",
      description:
        "diseñamos productos y programas a medida para compañías y colectivos encaminados a satisfacer las necesidades e intereses de sus clientes.",
    },
  },
  ourBrand: {
    title: "Nuestros compañeros",
    knowThem: "Conócelas",
    firstCard: {
      title: "MAPFRE Assistance",
      description:
        "Soluciones integrales de asistencia en cualquier parte del mundo",
    },
    secCard: {
      title: "MAPFRE Warranty",
      description:
        "Soluciones de garantía y protección del automóvil y del estilo de vida.",
    },
  },
  individual: {
    title: "Soluciones para \nParticulares",
    secondTitle: "Pensadas para protegerte a ti y a los tuyos",
    firstParagraph: `En MAPFRE Asistencia sólo tenemos un objetivo, garantizar tu tranquilidad
    y la de los tuyos allí donde te encuentres. Por ello estamos en
    constante evolución, desarrollando soluciones que se ajustan
    a las necesidades del día a día de las personas. Para que tú
    sólo te preocupes de disfrutar sin ningún riesgo
    y toda la protección de la mejor compañía.`,
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Soluciones para Particulares",
    },
    firstBlock: {
      title: "Soluciones adaptadas a tus necesidades",
      item0: {
        title: "Seguro de Viaje",
        subItem1:
          "Viaja tranquilo con la seguridad que da tener el respaldo de una gran compañía.",
        subItem2:
          "¿Has perdido alguna vez el equipaje en un vuelo? ¿Has tenido que cancelar un viaje a última hora? Si tienes algún percance o necesitas asistencia sanitaria durante al viaje ¿sabes a quién puedes acudir?",
        subItem3:
          "Asegura tu tranquilidad y la de tus acompañantes con los mejores seguros de viaje del mercado.",
        link1: "Segurviaje.com",
      },
      item1: {
        title: "Protección para tu automóvil",
        subItem1:
          "Protege tu vehículo ante fallos mecánicos, eléctricos o electrónicos fuera del periodo de garantía oficial.",
        subItem2:
          "¿Qué pasa si tu coche sufre una avería importante y no está cubierto por la garantía del fabricante? ¿Y si sufre algún golpe?",
        subItem3:
          "Protégete ante ésta u otras eventualidades que suponen elevados gastos imprevistos.",
        link1: "Más información Italia",
        link2: "Más información Turquía",
      },
      item2: {
        title: "Protección Lifestyle",
        subItem1:
          "Soluciones para proteger todo aquello que te rodea en tu día a día.",
        subItem2:
          "Protege tu móvil, tus electrodomésticos y todos los dispositivos que utilizas en tu vida diaria ante averías.",
        subItem3:
          "Protege también tu estilo de vida ante situaciones personales que puedan afectarla.",
      },
    },
  },

  solutionsCards: {
    card1: {
      name: "Your road. Our world",
      description: `Asistencia en Carretera.\n
      Servicios Complementarios de Asistencia en Carretera.`,
    },
    card2: {
      name: "Your travel. Our world",
      description: `Seguro de Viaje.\n
      Seguro de Cancelación.`,
    },
    card3: {
      name: "Your vehicle. Our world",
      description: `Garantía Mecánica para Vehículos.\n
        Repair Assist\n
        GAP`,
    },
    card4: {
      name: "Your lifestyle. Our world",
      description: `Protección de Tarjetas de Crédito.\n
      Protección de Préstamos.\n
      Protección de Pagos de Recibos.\n
      Protección de Ingresos.\n
      Protección de Dispositivos.`,
    },
    card5: {
      name: "Your home. Our world",
      description: `Asistencia Hogar.\n
      Servicios Complementarios de Asistencia a Hogar.`,
    },
    card6: {
      name: "Your life. Our world",
      description: `Asistencia a Personas.`,
    },
    // Programmes desgned for
    card7: {
      name: "Clientes Particulares y Empresas",
      description: `Clientes Particulares\nPremium\nStandard\nClientes Empresas\nGrande\nMediana\nPequeña`,
    },
    card8: {
      name: "Productos",
      description: `Cuentas Corrientes\nCuentas de Ahorro\nTarjetas de Débito\nTarjetas de Crédito\nCréditos Hipotecarios\nCréditos al consumo\nPlanes de Inversión\nPlanes de Jubilación`,
    },
    card9: {
      name: "Canales de distribución",
      description: `Oficinas\nCajeros\nOnline\nTelemarketing\nCall Center\nDirect Mail\nEmail\nEncartes\nRedes Sociales`,
    },
    card10: {
      name: "Gestión de Siniestros",
      description: `Gestión de Siniestros`,
    },
  },
  // Solutions for the Insurance Industry
  insuranceIndustryPage: {
    title: "Industria Seguros",
    secondTitle:
      "Más de 30 años incrementando la rentabilidad de \nlas compañías de seguros",
    firstParagraph: `<strong>Nuestra propuesta para el mercado asegurador</strong> \n Somos especialistas en asistencia y en el diseño y prestación de
     <strong>servicios a medida para las aseguradoras y sus carteras de asegurados.</strong>
       \nContamos con una red propia de asistencia en los 5 continentes. \nComo underwriters ofrecemos a las compañía de seguros nuestra experiencia en el diseño y comercialización de
       <strong>coberturas tales como, garantía mecánica de vehículos y electrodomésticos</strong>,   sGAP, PPI, y otros riesgos.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      lastPathName: "Industria Seguros",
    },
    firstBlock: {
      title: "Nuestros Seguros",
      tab1: "Hogar",
      tab2: "Automóvil",
      tab3: "Vida",
      tab4: "Salud",
      tab5: "Viaje",
    },
    secondBlock: {
      title: "Comercialización y Distribución",
      p1: "Los productos y servicios de MAPFRE Asistencia pueden incorporarse al portafolio de nuestro partner mediante <strong> esquemas de inclusión directa o distribuirse de forma opcional. </strong>",
      p2: "Para facilitar al máximo la comercialización de los productos, estos se ofrecen llave en mano en modo prestación de servicios o en modo distribución mediante suscripción directa por parte de MAPFRE Asistencia o de nuestro partner, acordando en este último caso un <strong> esquema de reaseguro. </strong>",
      p3: "Todos los programas y seguros están diseñados para poder ofrecerse bajo la marca de nuestro partner.",
    },
  },
  // here
  financialIndustry: {
    title: "Industria Finanzas",
    secondTitle:
      "Más de 30 años colaborando con empresas \ndel sector financiero",
    firstParagraph: `<strong>Nuestra propuesta para el mercado de Finanzas </strong> \n \n Definimos y ejecutamos con nuestros partners programas para incrementar la venta y activación de productos financieros. \n
    Disponemos también de coberturas aseguradoras para las tarjetas de crédito y las compras financiadas que, además de complementar y favorecer el uso de los productos financieros, constituyen una nueva fuente de ingresos para nuestros partners.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      lastPathName: "Finanzas",
    },
    firstBlock: {
      title: "Nuestros Seguross",
      tab1: "Bancos e Instituciones Financieras",
      tab2: "Programas diseñados para…",
    },
  },
  insuranceVehiculePage: {
    title: "Industria \nAutomoción",
    secondTitle:
      "Más de 30 años colaborando con \nempresas del sector automoción",
    firstParagraph: `<strong> Our proposition for the insurance market </strong> \n We are specialists in assistance  and in designing and providing <strong>customised services for insurers and their portfolios of insureds.</strong> \nWe have our own assistance network on all 5 continents. \nAs underwriters we offer insurance companies our experience in designing and <strong>marketing of coverage such as mechanical warranty for vehicles,</strong> such as mechanical warranty for vehicles, home appliance insurance, GAP, PPI, and other risks.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      lastPathName: "Automoción",
    },

    firstBlock: {
      title: "Nuestra propuesta para el mercado Automoción",
      p1: `Ofrecemos un portafolio de productos complementarios al automóvil para su comercialización y distribución. \n 
      Somos especialistas en <strong>programas de garantía mecánica y protección para vehículos nuevos y usados.</strong> \n 
      También disponemos de soluciones GAP y PPI para vehículos financiados, así como de otras coberturas para el automóvil. \n
      Nuestras soluciones proporcionan complementos de alto valor percibido que favorecen la decisión de compra e incrementan las ventas, \n
      además de suponer una nueva fuente de ingresos para nuestros partners.
      `,
    },
    secondBlock: {
      title: "Líneas de negocio para su empresa",
      p1: `Adaptamos nuestras soluciones a las necesidades de cada cliente: Fabricantes e Importadores de vehículos, Entidades Financieras, Entidades de Leasing/Renting, Concesionarios Oficiales, Distribuidores Independientes, Grandes distribuidores y Asociados de distribuidores, Red de Talleres Reparadores y Clubs del Automóvil.`,
    },
    thirdBlock: {
      title: "Nuestros Seguros",
    },
    fourthBlock: {
      title: "Comercialización y Distribución",
      p1: `Nuestros productos complementarios del automóvil se entregan llave en mano para su comercialización directa por parte de la red de venta de nuestro partner.
       Para favorecer las ventas contamos con una <strong >red propia de formación y de soporte comercial y de marketing a talleres y concesionarios </strong> en todo el mundo. \n 
       Todos nuestros productos pueden ser comercializados bajo la marca de nuestro partner. `,
    },
    fifthBlock: {
      title: "Somos parte del sector",
      p1: `<strong>Nuestro Centro de Experimentación y Seguridad Vial MAPFRE (CESVIMAP) </strong>  investiga todo sobre el automóvil desde 1983 y nos convierte en una referencia a nivel mundial en el sector.`,
    },
  },
  travelTourism: {
    title: "Industria Viajes \ny Turismo",
    secondTitle:
      "Más de 30 años colaborando con empresas \ndel sector viajes y turismo",
    firstParagraph: `<strong> Nuestra propuesta para el mercado de Viajes y Turismo </strong> \n \n El negocio del <strong>seguro de viaje y los programas de asistencia al viajero  </strong> más completos y competitivos disponibles para su comercialización a través de los canales offline y online de nuestros partners. \n\nDisponemos de <strong> la red de asistencia en viaje más grande del mundo: </strong> más de 31.500 puntos de asistencia en los 5 continentes, incluyendo hospitales, centros de emergencia, ambulancias, aeroambulancias, etc.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      lastPathName: "Viajes y turismo",
    },

    firstBlock: {
      title: "Nuestros Seguros",
      p1: `Adaptamos nuestras soluciones a las necesidades de cada cliente: Compañías Transportistas, Tour Operadores, Agencias de Viaje, Organizadores de Eventos, Ferias, Congresos y Convenciones, Compañías de Renta a Car, Portales Online, Hoteles, Customer Relation System y Global Distribution System.
      `,
    },

    secondBlock: {
      title: "Comercialización y Distribución",
      p1: `Desarrollamos nuestros seguros de viaje y servicios a medida y llave en mano, listos para su comercialización y distribución a través de la red de nuestro partner. \n
      Contamos con una red propia de formación y de soporte comercial y de marketing a <strong> gencias tradicionales y online </strong>  en todo el mundo.
    `,
    },
    thirdBlock: {
      title: "Reconocimientos del sector",
      p1: `Somos miembros afiliados de la Organización Mundial del Turismo (OMT). \n Hemos recibido los premios ITIJ 2005, 2011 y 2013 a la mejor compañía de seguro y asistencia en viaje. `,
    },
  },

  //insurances
  homeInsurance: {
    title: `Your home. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un mundo en el que sus asegurados puedan disfrutar de la mejor asistencia en el hogar. Las coberturas más avanzadas que satisfacen las necesidades de sus clientes y les aportan una total tranquilidad.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      thirdPathName: "Industria Seguros",
      lastPathName: "Your home. Our world",
    },
    firstBlock: {
      title: "Coberturas",
      coverages: {
        items1: {
          title: "Asistencia Hogar",
          description:
            "Servicio disponible 24 horas, 365 días al año que repara con la mayor rapidez cualquier situación de emergencia surgida en hogares previniendo así perjuicios mayores.",
        },
        items2: {
          title: "Servicios Complementarios de Asistencia a Hogar",
          description:
            "Servicios innovadores que complementan la propuesta de asistencia a hogar, y que son valoradas positivamente por los usuarios. Como el envío o conexión con profesionales, que proporciona al asegurado acceso a redes de profesionales y colaboradores de cualquier ámbito (técnico, reparadores, mantenimiento, mudanzas, etc.) con tarifas ventajosas gracias a los acuerdos con nuestros proveedores.",
        },
      },
    },
  },
  lifeStyle: {
    title: `Your lifestyle. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un mundo en el que MAPFRE Warranty ofrece una amplia gama de seguros y coberturas que protegen todo tipo de objetos y la utilización de tarjetas de crédito`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      thirdPathName: "Industria Seguros",
      lastPathName: "Your lifestyle. Our world",
    },
    firstBlock: {
      title: "Productos",
      coverages: {
        items1: {
          title: "Protección de Tarjetas de Crédito",
          description:
            "Asegura las compras realizadas con tarjeta de crédito, compensando al asegurado en caso de que encuentre más barato un producto adquirido con la tarjeta, el atraco después de haber sacado dinero en un cajero, los daños o robo de un bien comprado con tarjeta o el uso fraudulento de la misma.",
        },
        items2: {
          title: "Protección de Préstamos",
          description:
            "Protección de pagos que exenta al asegurado de pagar las cuotas correspondientes a un préstamo personal, por la compra de un vehículo o préstamo hipotecario, en caso de incapacidad, enfermedad o desempleo por causas ajenas a su voluntad.",
        },
        items3: {
          title: "Protección de Pagos de Recibos",
          description:
            "Cobertura que asegura el pago de las facturas mensuales de los servicios de electricidad, agua, gas, teléfono, internet, etc. ofreciendo tranquilidad absoluta al cliente ante los supuestos de accidente, enfermedad o desempleo involuntario.",
        },
        items4: {
          title: "Protección de Ingresos",
          description:
            "Permite a nuestros socios ofrecer la máxima tranquilidad a sus clientes, protegiendo a su familia y estilo de vida ante una situación que le impida trabajar.",
        },
        items5: {
          title: "Protección de Dispositivos",
          description:
            "Coberturas que proporcionan tranquilidad a sus clientes, protegiendo bienes de uso cotidiano como electrodomésticos, aparatos informáticos, teléfonos u otros dispositivos móviles.",
        },
      },
    },
  },
  road: {
    title: `Your road. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un mundo en el que MAPFRE Assistance acude de forma rápida y eficaz con la solución más adecuada para sus clientes. Llevamos más de dos décadas diseñando programas de asistencia a asegurados ante contingencias en la carretera. Identificando problemas y desarrollando los servicios que nos han convertido en unos grandes expertos del sector.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      thirdPathName: "Industria Seguros",
      lastPathName: "Your road. Our world",
    },
    firstBlock: {
      title: "Coberturas",
      coverages: {
        items1: {
          title: "Asistencia en Carretera",
          description:
            "Cubre las necesidades de asistencia del vehículo, del conductor y sus acompañantes cuando surge alguna contingencia o dificultad que le impide continuar con su viaje por accidente o avería del vehículo.",
        },
        items2: {
          title: "Servicios Complementarios de Asistencia en Carretera",
          description:
            "Servicios que pueden diseñarse a medida de los requerimientos de la aseguradora, y que van encaminados a incrementar su oferta, como el servicio de vehículo de sustitución, o de envío o conexión con profesionales, que proporciona al asegurado acceso a redes de colaboradores con tarifas ventajosas gracias a los acuerdos con nuestros proveedores.",
        },
      },
    },
  },
  car: {
    title: `Your vehicle. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un mundo en el que MAPFRE Warranty ofrece la línea más completa de productos y coberturas adicionales al seguro del automóvil. Soluciones ad hoc
    que nos convierten en referente del sector.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      thirdPathName: "Industria Seguros",
      lastPathName: "Your vehicle. Our world",
    },
    firstBlock: {
      title: "Coberturas",
      coverages: {
        items1: {
          title: "Garantía Mecánica para Vehículos",
          description:
            "Servicio que cubre la avería mecánica, eléctrica o electrónica que se produzca en un vehículo usado o nuevo. iWarrantty, es además, una cobertura especialmente diseñada para incluir en la póliza del seguro.",
        },
        items2: {
          title: "Repair Assist",
          description:
            "Asesoramiento telefónico de mecánicos especialistas que valorarán las operaciones a realizar y el coste estimado de reparación e incluso negociando con el taller. En caso de que el cliente lo desee, se propondrán proveedores alternativos para realizar la intervención.",
        },
        items3: {
          title: "GAP",
          description:
            "Cobertura que hace frente a la depreciación o al crédito pendiente de pago de un vehículo en el momento de ser declarado siniestro total por incendio, accidente o robo.",
        },
      },
    },
  },
  life: {
    title: `Your life. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un mundo en el que sus asegurados podrán contar con una asistencia de calidad en el menor tiempo posible y en cualquier parte del mundo.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      thirdPathName: " Industria Seguros",
      lastPathName: "Your life. Our world",
    },
    firstBlock: {
      title: "Coberturas",
      coverages: {
        items1: {
          title: "Asistencia a Personas",
          description: `Servicios innovadores que complementan la propuesta de asistencia.
          Segunda opinión médica, orientación telefónica (legal, salud, nutrición,…), servicios de conciergerie, asistencia funeraria, etc.
          Adicionalmente, se proporciona al asegurado acceso a redes de profesionales con tarifas ventajosas gracias a los acuerdos con nuestros proveedores.`,
        },
      },
    },
  },
  travel: {
    title: `Your travel. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un mundo en el que sus asegurados podrán disfrutar del placer de viajar con total tranquilidad a cualquier parte del mundo, con la garantía y el respaldo de la gama más completa de seguros de viaje.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Soluciones Corporativas",
      thirdPathName: "Industria Seguros",
      lastPathName: "Your travel. Our world",
    },
    firstBlock: {
      title: "Coberturas",
      coverages: {
        items1: {
          title: "Seguro de Viaje",
          description: `Protección personalizada al viajero y a sus acompañantes para cada destino y tipo de viaje. Las coberturas se adaptan a la medida de las compañías aseguradoras y sus clientes: desde asistencia médica cubriendo los eventuales costes asociados a los caros tratamientos médicos que puedan surgir durante un viaje, seguro de cancelación del viaje, seguro de pérdida de equipaje, hasta asistencia en carretera.`,
        },
        items2: {
          title: "Seguro de Cancelación",
          description: `El seguro que permite planificar viajes con antelación y que aporta tranquilidad ante la posible cancelación de un viaje, vacaciones, billetes o un evento, entre otros, cubriendo los gastos no recuperables por el asegurado.`,
        },
      },
    },
  },
  findUsPage: {
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Contacta con nosotros",
    },
  },
  tunisia: "TÚNEZ",
  phoneAssurance: {
    title: "Garantía del teléfono móvil",
    smalmTitle: "Garantía de rotura accidental",
    breadCrumbs: {
      thirdPathName: "Garantía del teléfono móvil",
    },
    description: `La Garantía de Rotura Accidental de Smartphone es un servicio que cubre y corre con los gastos de reparación o sustitución del dispositivo en caso de Daños causados por caídas o golpes. \n 
    Si su producto se rompe durante el período de Garantía de rotura, ¡será reparado o reemplazado de forma gratuita! \n
    La Garantía por rotura accidental entra en vigor inmediatamente en el momento de la compra del dispositivo y finaliza en la fecha de vencimiento (el primer año a partir de la fecha de compra)
    `,
    firstTitle: "En caso de Rotura Accidental ofrecemos:",
    condition1: `Reparación del dispositivo dañado: incluye el costo de la mano de obra y el precio de las piezas`,
    secondTitle:
      "O Reemplazo por otro dispositivo de similares características y calidad si:",
    condition2: `El dispositivo cubierto no es reparable`,
    condition3: `Las piezas de repuesto no están disponibles.`,
    condition4: `Los costos de reparación cuestan más que el producto en sí`,
  },
  machineAssurance: {
    title: "Garantía marrón y blanca",
    smalmTitle: "Garantía de rotura accidental",

    description: `La extensión de garantía Brown & White es un servicio que cubre y corre con el costo de reparar o reemplazar el electrodoméstico en caso de que ocurra una falla después del vencimiento de la garantía del fabricante. \n 
    Si su producto experimenta un problema mecánico, electrónico o eléctrico cubierto durante el período de garantía extendida, ¡será reparado o reemplazado sin cargo! \n
    La Extensión de Garantía se contrata el mismo día de la compra del dispositivo amparado, surte efecto a la expiración de la garantía contractual del fabricante y ofrece las mismas garantías que el fabricante con la posibilidad de reposición del producto.\n
    En cas d'une défaillance mécanique, électrique ou électronique survenue après l'expiration de la Garantie Constructeur et selon les termes de la garantie, nous proposons :

    `,
    firstTitle: "La reparación del dispositivo incluye: ",
    condition1: `costos de mano de obra y precios de repuestos`,
    secondTitle:
      "O Reemplazo por otro dispositivo de similares características y calidad si:",
    conclusion1: "La Extensión de Garantía puede ser de 1 o 2 o 3 años",
    conclusion2:
      "La garantía extendida mejora el producto y brinda tranquilidad ante el riesgo de gastos imprevistos",
  },
  NEWS: "NOTICIAS",
  phone1: "la administración",
  phone2: "Centro de llamadas - Soporte 24/7",
  phone3: "WhatsApp",
  copyRight: {
    title: "Derechos de autor",

    firstParagraph: `Creada en 1991, AFRIQUE ASSISTANCE es una empresa tunecina-española certificada
    ISO 9001-2008, parte del grupo MAPFRE ASSISTANCE.`,
    secondParagraph: `AFRICA ASSISTANCE es líder en el sector de la asistencia en el continente africano.
    continente. Dispone de una completa red de proveedores para satisfacer las necesidades diarias de sus
    necesidades de sus clientes. La empresa ofrece una red de proveedores para :`,
    li1: `Atención integral: para el vehículo, el conductor y todos los pasajeros.
    pasajeros. `,
    li2: `Servicios de asistencia al automóvil "de camiones de averías, talleres de vehículos, alquiler de coches
    talleres, agencias de alquiler de coches...".`,
    li3: `Una red de proveedores de asistencia en viaje "taxis, compañías aéreas, hoteles, hospitales
    aerolíneas, hoteles, hospitales, médicos, enfermeras y otros profesionales de la salud
    ambulancias, aviones, instalaciones sanitarias, etc.",`,
    li4: `Una red de proveedores de asistencia a domicilio "fontaneros, cristaleros, electricistas
    fontaneros, cristaleros, electricistas, cerrajeros...". `,
    li5: `una red de proveedores de talleres "Cristalería del automóvil, gestión de siniestros, talleres concertados
    garajes...".`,
    li6: `Una red de reparadores "técnicos, centros de servicio
    electrodomésticos, teléfonos móviles...".`,
    lastParagraph: `AFRICA ASSISTANCE presta servicios a varias empresas, entre ellas compañías de seguros
    compañías de seguros, instituciones financieras (bancos, empresas de leasing y concesionarios)
    empresas de leasing y concesionarios), así como los hipermercados.`,
  },
};

import React from 'react'
import "./landingPageMidCard.css"
import { ChevronRightIcon } from '@heroicons/react/outline'
import { ElevenMp } from '@mui/icons-material'


export default function LandingPageCard(props) {
  return (
    <div className='landingPageMidCard'>
      <div className="landingpageMidCardPhotoContainer">
        <img src={props.photo} alt="" />
      </div>
      <h2>{props.title}</h2>
      <p>{props.paragraph}</p>
      <a href={`/#/${props.path}`}>{props.link} <ChevronRightIcon className="w-4 ml-1 cursor-pointer" /></a>



    </div>
  )
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function TextWithCheckBox(props) {
  return (
    <div className="textWithCheckBox">
      <FontAwesomeIcon icon={faCheck} />
      <p>
        {props.text}
      </p>
    </div>
  );
}

import React from 'react'
import BasicBreadcrumbs from '../../components/breadcrumbs/BreadCrumbs'
import { useTranslation } from "react-i18next";
import "../ourHistory/ourHistory.css"

export default function OurHistory() {
  const { t } = useTranslation();

  return (
    <div>
        <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("figures.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
                pathname: t("figures.breadCrumbs.lastPathName"),
                type: "static",
            },
          ]}
        />
      </div>

          <h1 className='bigTitle'>
            {t("figures.title")}
          </h1>
      <div className='historyContainer'>
        {
            localStorage.getItem("lng") === "en" ? 
          <img src="images/figures/figure.webp" alt="" />

            : 
            <img src="images/figures/figureES.webp" alt="" />

        }
        
        </div>    

    </div>
  )
}

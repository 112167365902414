import React from 'react'
import LandingPageCard from '../../../components/cards/LandingPageCard'
import "./landingPageMidSection.css"
import { useTranslation } from "react-i18next";


export default function LandingPageMidSection() {
  const { t } = useTranslation();

  let cardsData = [
    {
      title: t("midSectionCardFirstCardTitle"),
      paragraph: t("midSectionCardFirstCardp"),
      photo: "images/home/midCardsFirst.webp",
      link: t("midSectionCardFirstCardLink"),
      path: "corporate-solutions"

    },
    {
      title: t("midSectionCardSecondCardTitle"),
      paragraph: t("midSectionCardSecondCardp"),
      photo: "images/home/midCardsSecond.webp",
      link: t("midSectionCardSecondCardLink"),
      path: "solutions-for-individuals"



    },
    {
      title: t("midSectionCardThirdCardTitle"),
      paragraph: t("midSectionCardThirdCardp"),
      photo: "images/home/midCardsThird.webp",
      link: t("midSectionCardThirdCardLink"),
      path: "find-us"



    },
  ]


  return (
    <div className='landingPageMidSection'
    >
      <h1 className='MidTitle'>
        {t("findUsPage.secondTitle")}
      </h1>
      <div className="midsectionCardsContainer">
        {cardsData.map(elm => (
          <LandingPageCard  {...elm} />
        ))}
      </div>
    </div>
  )
}

import React from "react";
import { useTranslation } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import ContactCard from "../../components/cards/ContactCard";
import "./findUs.css"
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faHeadphonesSimple, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import Marquee from "react-fast-marquee";


const FindUs = () => {
  const { t } = useTranslation();

  let contactInfo = [
    {
      title : t("phone1"),
      number: localStorage.getItem("lng") === "ar" ? "511 104 71" : "71 104 511",
      icon: faPhoneVolume
    },
    {
      title : t("phone2"),
      number:  localStorage.getItem("lng") === "ar" ? "555 104 71" : "71 104 555",
      icon: faHeadphonesSimple

    },
    {
      title : t("phone3"),
      number:localStorage.getItem("lng") === "ar" ? "000 783 26" :  "26 783 000" ,
      icon: faWhatsapp
    },
  ]

  return (
    <div className="aboutPage">
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("findUsPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("findUsPage.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>
      <div className="block contactInfo">
        {contactInfo.map(elm => (
          <ContactCard {...elm} />
        ))}
      </div>

      <div className="mapLocation">
        <div className="locationInfo">
          <p>Inmeuble Tamayouz, 4 ème étage</p>
          <p>Centre Urbain Nord, {t("tunisia")}</p>
          <p>1082</p>
        </div>
      </div>

      <div className="partners">

        <Marquee loop={0}
          speed={120}
          gradientWidth={0}
          pauseOnHover={true}
        >
          <img src="partners/AMAN.png" alt="" />
          <img src="partners/BIAT.png" alt="" />
          <img src="partners/GAT.png" alt="" />
          <img src="partners/MAE.png" alt="" />
          <img src="partners/STAR.png" alt="" />
          <img src="partners/ZITOUNA.png" alt="" />
          <img src="partners/ASTREE.png" alt="" />
        </Marquee>



      </div>

    </div>
  );
};

export default FindUs;

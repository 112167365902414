import React, { useEffect } from 'react'
import "./menu.css"
export default function Menu({ options, handleClose }) {

    useEffect(() => {
        const concernedElement = document.querySelector(".costum-nav-menu");
        document.addEventListener("mousedown", (e) => {
            // console.log(concernedElement, e.target);
            if (concernedElement.contains(e.target)) {
                console.log("clicked inside");
                console.log(e.target);
            } else {
                if (e.target.getAttribute("name") !== "nestedMenu") {
                    console.log("clicked outside");

                    handleClose();
                }
            }
        });


    }, [])

    return (
        <div className='costum-nav-menu' name="nestedMenu">
            <p name="nestedMenu">{options.title}</p>
            <div className='menuLinks' name="nestedMenu">
                {
                    options.items && options.items.map(elm => (
                        <a name="nestedMenu" href={`/#${elm.path}`}>{elm.name}</a>
                    ))
                }
            </div>

        </div>
    )
}

import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./innovation.css"

export default function Innovation() {
  const { t } = useTranslation();


  return (
    <div className="aboutPage">
      <div className="aboutPageBg innovation" >
        <h1>{t("innovationPage.title")}</h1>
        <h3>{t("innovationPage.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("innovationPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
                pathname: t("innovationPage.breadCrumbs.lastPathName"),
                type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle"  >
        <Trans i18nKey="innovationPage.firstParagraph">
          MAPFRE Asistencia is a{" "}
          <strong>global insurance, reinsurance and services company </strong>{" "}
          founded in Madrid (Spain) in 1989 and operating worldwide. We are
          present directly in <strong> 27 offices </strong>, and have more than
          <strong>
            {" "}
            1,250 corporate partners, with more than 100 million people who
            benefit from our services.
          </strong>
        </Trans>
      </div>

    </div>
  );
}

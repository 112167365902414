import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./insurances.css"
import CheckedItems from "../../components/checkedItems/CheckedItems";
import TextWithCheckBox from "../../components/cards/TextWithCheckBox";

export default function BlackAndWhite() {
  const { t } = useTranslation();


  return (
    <div className="aboutPage">
      <div className="aboutPageBg insuranceIndustry machine">
        <h1>{t("machineAssurance.title")}</h1>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("car.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("car.breadCrumbs.secondPathName"),
              type: "link",
              to: "/corporate-solutions",
            },

            {
              pathname: t("machineAssurance.title"),
            },

          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="machineAssurance.description"></Trans>
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("machineAssurance.firstTitle")}
        </h1>
        <TextWithCheckBox text={t("machineAssurance.condition1")} />
        <h1 className="underLinedTitle">
          {t("machineAssurance.secondTitle")}
        </h1>
        <TextWithCheckBox text={t("phoneAssurance.condition2")} />
        <TextWithCheckBox text={t("phoneAssurance.condition3")} />
        <TextWithCheckBox text={t("phoneAssurance.condition4")} />
        <h1 className="conclusion"><span>{">"}</span>{t("machineAssurance.conclusion1")}</h1>
        <h1 className="conclusion"><span>{">"}</span>{t("machineAssurance.conclusion2")}</h1>

      </div>

    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header/Header";
import i18n from "i18next";
import "./translations/i18n";
import { useTranslation } from "react-i18next";
import LandingPage from "./pages/landingPage/LandingPage";
import Footer from "./components/footer/Footer";
import MainRouter from "./routers/MainRouter";

function App() {
  const { t } = useTranslation();

  return (
    <div
      className={`App ${localStorage.getItem("lng") === "ar" && "rtl"}`}
      dir={localStorage.getItem("lng") === "ar" && "rtl"}
    >
      <Header />
      <MainRouter />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'
import BasicBreadcrumbs from '../../components/breadcrumbs/BreadCrumbs'
import { useTranslation } from "react-i18next";
import "./ourHistory.css"

export default function OurHistory() {
  const { t } = useTranslation();

  return (
    <div>
        <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("ourHistoryPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
                pathname: t("ourHistoryPage.breadCrumbs.lastPathName"),
                type: "static",
            },
          ]}
        />
      </div>

          <h1 className='bigTitle'>
            {t("ourHistoryPage.title")}
          </h1>
      <div className='historyContainer'>
        {
            localStorage.getItem("lng") === "en" ? 
          <img src="images/history/history.webp" alt="" />

            : 
            <img src="images/history/historyES.png" alt="" />

        }
        
        </div>    

    </div>
  )
}

import React from 'react'
import News from '../../components/news/News'
import "./landingPage.css"
import LandingPageLastSection from './landingPageLastSection/LandingPageLastSection'
import LandingPageMidSection from './landingPageMidSection/LandingPageMidSection'
import LandingPageTopSection from "./landingPageTopSection/LandingPageTopSection"
export default function LandingPage() {
  return (
    <div className='landingPageContainer'>
      <LandingPageTopSection />
      <News />
      <LandingPageMidSection />
      <LandingPageLastSection />
    </div>
  )
}

import React from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  const { t } = useTranslation();
  const data = {
    en: "/docs/politicsEn.pdf",
    ar: "/docs/politicsAr.pdf",
    fr: "/docs/politicsFr.pdf",
    es: "/docs/politicsEs.pdf",
  };
  return (
    <div className="footer">
      <div className="footerContainer">
        <div className="footerContent">
          <h6>{t("footer.title")}</h6>
          <a href={`/#/copy-right`}>{t("footer.subTitleOne")}</a>

          {localStorage.getItem("i18nextLng") === "fr" && (
            <a href={"/docs/politicsFr.pdf"} target="_blank">
              {t("footer.subTitleTwo")}
            </a>
          )}
          {localStorage.getItem("i18nextLng") === "en" && (
            <a href={"/docs/politicsEn.pdf"} target="_blank">
              {t("footer.subTitleTwo")}
            </a>
          )}
          {localStorage.getItem("i18nextLng") === "ar" && (
            <a href={"/docs/politicsAr.pdf"} target="_blank">
              {t("footer.subTitleTwo")}
            </a>
          )}
          {localStorage.getItem("i18nextLng") === "es" && (
            <a href={"/docs/politicsEs.pdf"} target="_blank">
              {t("footer.subTitleTwo")}
            </a>
          )}
        </div>
        <div className="footerCopyRight">
          <h3>{t("footer.copyRight")}</h3>
          <div className="socialMedia">
            <a
              href="https://www.linkedin.com/company/mapfre.assistance/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>

            <a
              href="https://www.facebook.com/mapfre.asistencia"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.youtube.com/user/MAPFREASISTENCIAvcr"
              target="_blank"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export const TRANSLATIONS_FR = {
  //navbar
  about: {
    title: "A propos ",
    nav: {
      item0: "A propos ",
      item1: "Notre histoire",
      item2: "Chiffres de base",
    },
  },
  solutions: {
    title: "Solutions",
    nav: {
      item0: "Solutions pour les Entreprises",
      item1: "Solutions pour les particuliers",
    },
  },
  brand: {
    title: "Nos partenaires",
  },
  innovation: {
    title: "Actualités",
  },
  findUs: {
    title: "Contactez-nous",
  },

  // page home

  // first cards

  firstCardTitle: "Assistance et services",
  firstCardP:
    "Nous protégeons plus de 100 millions de personnes dans le monde entier en leur garantissant ce qui compte le plus : leur tranquillité d'esprit et celle de leur famille.",
  firstCardTopButton: "Solutions pour les Entreprises",
  firstCardBottomButton: "Solutions pour les Particuliers",

  secondCardTitle: "Assurance voyage",
  secondCardp:
    "La meilleure assurance voyage avec le plus grand réseau d'assistance aux voyageurs du monde.",

  thirdCardTitle: "Assistance routière",
  thirdCardp:
    "Nous protégeons les investissements des particuliers dans les véhicules, en proposant des programmes de garantie., \n protection de prêt, GAP et autres couvertures de véhicules.",

  // mid cards

  midSectionCardFirstCardTitle: "Solutions pour les Entreprises",
  midSectionCardFirstCardp:
    "Des produits et services qui aident nos partenaires à développer leurs activités et à générer des avantages et une protection pour leurs clients.",
  midSectionCardFirstCardLink: "Entreprise",

  midSectionCardSecondCardTitle: "Solutions pour les Particuliers",
  midSectionCardSecondCardp:
    "Nous sommes à votre disposition pour répondre à vos besoins et à ceux de votre famille, où que vous soyez dans le monde. Notre mission est de garantir votre tranquillité d'esprit pour que vous puissiez vous reposer en toute sérénité.",
  midSectionCardSecondCardLink: "Individus",

  midSectionCardThirdCardTitle: "Contactez-nous",
  midSectionCardThirdCardp:
    "Une présence directe dans 33 pays et sur cinq continents, garantissant l'excellence opérationnelle partout dans le monde.",
  midSectionCardThirdCardLink: "Contactez-nous",
  //home page last section

  homePageLastSectionTitle: "Protection du mode de vie",
  homePageLastSectionParagraph:
    "Garanties des dispositifs électroniques et des appareils électriques. \nProtection des prêts et des achats, et autres assurances : la tranquillité d'esprit.",

  //general ,

  moreInfo: "Plus d'informations",

  //footer
  footer: {
    title: "Plus d'informations",
    subTitleOne: "Copyright Afrique assistance",
    subTitleTwo: "Politique de confidentialité",

    copyRight: "Afrique assistance © 2022 Tous droits réservés",
  },
  ourHistoryPage: {
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Notre histoire",
    },

    title: "Notre histoire",
  },

  figures: {
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Chiffres de base",
    },
    title: "Principaux chiffres",
  },
  aboutPage: {
    title: "MAPFRE Asistencia",
    secondTitle:
      "Un groupe mondial d'assurance, de réassurance \n et de services",
    firstParagraph: ` MAPFRE Asistencia est un <strong >assurance, réassurance et services mondiaux
      société </strong> fondée à Madrid (Spain) en 1989 et opérant dans le monde entier. Nous
      sommes présents directement dans <strong >  27 bureaux ,</strong> et ont plus de<strong > 1 300 entreprises
      partenaires, avec plus de 100 millions de personnes qui bénéficient de nos
      services.</strong>`,

    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "A propos ",
    },

    firstBlock: {
      title: "MAPFRE Asistencia",
      p1: `Nous proposons des produits qui augmentent la rentabilité de nos partenaires et améliorent la qualité de vie des gens. Nous sommes spécialisés dans les solutions globales d'assistance, de protection des véhicules et de style de vie :`,
      p2: `Notre <strong>Assistance</strong> Ce réseau nous aide à prendre soin des personnes et des biens dans le monde entier. Nous fournissons une assistance en cas d'incidents imprévisibles sur le bord de la route et à domicile, ainsi qu'une assistance médicale et de voyage et d'autres services visant à améliorer la qualité de vie.`,
      p3: `Nous protégeons les investissements des gens dans les véhicules, en offrant   <strong>programmes de garantie</strong>La garantie prolongée, la protection des prêts, l'IPP, la GAP et la couverture d'autres véhicules.</strong>  `,
      p4: `Notre <strong>Mode de vie</strong> offrent la tranquillité d'esprit en protégeant les objets quotidiens tels que les appareils électroménagers, les ordinateurs, les téléphones ou appareils mobiles et les cartes de crédit.`,
      p5: `Nous sommes leaders dans la fourniture de solutions pour le secteur des assurances, y compris des solutions spécifiques pour les courtiers, ainsi que pour les secteurs de la finance, de l'industrie automobile, des voyages et du tourisme. Nous concevons également des produits personnalisés pour les entreprises d'autres secteurs et pour les grands groupes.`,
      p6: `Certains de nos produits et services peuvent être achetés directement par les particuliers. `,
    },
    secondBlock: {
      title: "Prix et certifications",
      p1: "Notre excellence a été récompensée, entre autres, par le prix ITIJ 2005, qui nous certifie comme la meilleure compagnie d'assistance internationale, et par les prix ITIJ 2011 et 2013 de la meilleure compagnie d'assurance. Nous avons également obtenu la certification internationale de qualité ISO 9001.",
    },
  },

  innovationPage: {
    title: "Actualités",
    secondTitle: "Une caractéristique essentielle de MAPFRE Asistencia",
    firstParagraph:
      "“La volonté d'innover est indissociable de toutes les facettes et de tous les processus de notre entreprise et nous amène à constituer des équipes qui, comme un laboratoire, tournent à plein régime, avec des spécialistes prêts à prendre en charge des projets dans des délais impossibles pour quiconque, avec des personnes capables d'extraire plus de synergies plus rapidement que la plupart des autres, et avec des équipes de travail qui proposent des solutions créatives.”",
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Actualités",
    },
  },

  brandsPage: {
    title: "Nos partenaires",
    secondTitle: "Assistance MAPFRE et garantie MAPFRE",
    firstParagraph:
      "Dans tous les cas, nos produits et services étendent et complètent l'offre commerciale de nos partenaires et peuvent être commercialisés et distribués sous leur propre marque.",
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Nos partenaires",
    },
  },

  corporate: {
    title: "Solutions  \nd'entreprise",
    secondTitle: "Plus de 30 ans à accroître \n Plus de 30 ans à accroître",
    firstParagraph: `Nous offrons une gamme de produits et de services pour la protection des personnes et des biens couvrant des besoins toujours croissants. L'incorporation ou la distribution de ces services,`,
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Solutions  d'entreprise",
    },
    // here
    firstBlock: {
      title: "Solutions",
      item0: {
        title: "Solutions pour le secteur de l'assurance",
        description: `Nous disposons de notre propre réseau d'assistance et de prestation de services adapté à des portefeuilles d'assurés sur les 5 continents.
          Nos souscripteurs proposent également des couvertures d'assurance pour la protection des véhicules et du style de vie.`,
      },
      item1: {
        title: "Solutions pour l'industrie automobile",
        description: `Nous sommes spécialisés dans la conception et le soutien à la commercialisation et à la distribution de programmes de garantie et de protection mécaniques pour les véhicules neufs et d'occasion.`,
      },
      item2: {
        title: "Solutions pour l'industrie du voyage et du tourisme",
        description: `Nous proposons des programmes d'assurance voyage et d'assistance médicale pour une distribution hors ligne ou en ligne.Récompensé en 2011 et 2013 par le prix ITIJ de la meilleure compagnie d'assurance voyage et membre affilié de l'Organisation mondiale du tourisme (OMT).`,
      },
      item3: {
        title: "Solutions pour le secteur financier",
        description: `Nous développons des programmes visant à augmenter et à activer les produits financiers, ainsi que des assurances pour les cartes de crédit et les achats à tempérament ou avec financement.`,
      },
      item4: {
        title: "Garantie des téléphones portables",
        description: `La garantie contre les bris accidentels de smartphones est un service qui couvre et
          prend en charge les frais de réparation ou de remplacement de l'appareil en cas de
          Dommages causés par une chute ou un choc`,
      },
      item5: {
        title: "Garantie  brown & white",
        description: `L'extension de garantie Brown White est un service qui couvre et prend en charge
          prend en charge les frais de réparation ou de remplacement de l'appareil en cas de panne
          survenue après l'expiration de la garantie du fabricant`,
      },

      moreInfo: "Plus d'informations",
    },
    secondBlock: {
      title: "Et aussi...",
      description:
        "nous concevons des produits et des programmes personnalisés pour les entreprises et les groupes afin de satisfaire les besoins et les intérêts de leurs clients.",
    },
  },
  ourBrand: {
    title: "Nos partenaires",
    knowThem: "Les connaître",
    firstCard: {
      title: "MAPFRE Assistance",
      description:
        "Des solutions d'assistance complètes partout dans le monde.",
    },
    secCard: {
      title: "Garantie MAPFRE",
      description:
        "Solutions de garantie et protection des véhicules et du mode de vie.",
    },
  },
  individual: {
    title: "Solutions pour \nindividus",
    secondTitle: "Conçu pour vous protéger, vous et les vôtres",
    firstParagraph: `Notre seul objectif, chez MAPFRE Asistencia, est de garantir votre tranquillité d'esprit et celle des vôtres, où que vous soyez. C'est pourquoi nous évoluons constamment, en développant des solutions adaptées au quotidien des personnes. Pour que vous n'ayez à vous soucier que de vous amuser sans aucun risque et avec la protection totale de la meilleure compagnie.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Solutions pour les particuliers",
    },
    firstBlock: {
      title: "Des solutions adaptées à vos besoins",
      item0: {
        title: "Assurance voyage",
        subItem1:
          "Voyagez en toute sérénité en sachant que vous avez le soutien d'une grande entreprise.",
        subItem2:
          "Avez-vous déjà perdu vos bagages sur un vol ? Avez-vous déjà dû annuler un voyage à la dernière minute ? Si vous avez un problème ou besoin d'une assistance médicale pendant votre voyage, savez-vous à qui vous adresser ?",
        subItem3:
          "Garantissez votre tranquillité d'esprit et celle de vos compagnons de voyage avec la meilleure assurance voyage disponible sur le marché.",
        link1: "Segurviaje.com",
      },
      item1: {
        title: "Protection de votre véhicule",
        subItem1:
          "Protège votre véhicule contre les défaillances mécaniques, électriques ou électroniques au-delà de la période de garantie officielle.",
        subItem2:
          "Que se passe-t-il si votre voiture tombe en panne et n'est pas couverte par la garantie du constructeur ? Que se passe-t-il si elle a un accident ?",
        subItem3:
          "Protégez-vous contre ces événements et d'autres qui peuvent donner lieu à des dépenses élevées et imprévisibles.",
        link1: "Plus d'informations sur l'Italie",
        link2: "Plus d'informations sur la Turquie",
      },
      item2: {
        title: "Protection du mode de vie",
        subItem1:
          "Des solutions pour protéger tout ce qui vous entoure au quotidien.",
        subItem2:
          "Protégez votre téléphone portable, vos appareils ménagers et tous les autres appareils que vous utilisez quotidiennement contre les pannes.",
        subItem3:
          "Protégez également votre style de vie contre les situations personnelles qui pourraient vous affecter.",
      },
    },
  },

  solutionsCards: {
    card1: {
      name: "Votre route. Notre monde",
      description: `Assistance routière\n
        Services complémentaires d'assistance routière`,
    },
    card2: {
      name: "Votre voyage. Notre monde",
      description: `Assurance voyage\n
        Assurance annulation de voyage`,
    },
    card3: {
      name: "Votre véhicule. Notre monde",
      description: `Garantie mécanique pour les véhicules\n
        Aide à la réparation\n
          GAP`,
    },
    card4: {
      name: "Votre style de vie. Notre monde",
      description: `Protection des cartes de crédit.\n
        Protection des prêts.\n
        Protection du paiement des factures.\n
        Protection du revenu.\n
        Protection des dispositifs.`,
    },
    card5: {
      name: "Votre maison. Notre monde",
      description: `Assistance à domicile\n
        Services complémentaires à domicile`,
    },
    card6: {
      name: "Votre vie. Notre monde",
      description: `Assistance personnelle`,
    },
    // Programmes desgned for
    card7: {
      name: "Clients Individuals and Companies",
      description: `<strong>Individus</strong>\n\n  Premium \n\n  Standard\n \n<strong>Entreprises</strong> \n\n Large \n\n Moyen \n\n Petit `,
    },
    card8: {
      name: "Produits",
      description: `Comptes courants \n \n Comptes d'épargne \n \n Cartes de débit \n \n Cartes de crédit \n \n Prêts hypothécaires \n\n Crédit à la consommation \n \n  Plans d'investissement \n  \n  Plans de retraite`,
    },
    card9: {
      name: "Canaux de distribution",
      description: `Bureaux \n \n Distributeurs automatiques de billets \n \n En ligne \n \n Télémarketing \n \n Centre d'appels \n \n Courrier direct \n \n Courriel : \n \n Inserts \n \n Médias sociaux`,
    },
    card10: {
      name: "Gestion des réclamations",
      description: `Gestion des réclamations`,
    },
  },
  // Solutions for the Insurance Industry
  insuranceIndustryPage: {
    title: "Secteur des assurances",
    secondTitle:
      "Plus de 30 ans à accroître la rentabilité des  \ncompagnies d'assurance",
    firstParagraph: `<strong> Notre proposition pour le marché de l'assurance </strong> \n Nous sommes spécialisés dans l'assistance et dans la 
      conception et la fourniture de <strong>des services personnalisés pour les assureurs et leurs portefeuilles d'assurés.</strong>
       \nNous disposons de notre propre réseau d'assistance sur les 5 continents. \nEn tant que souscripteurs, nous offrons aux compagnies d'assurance notre expérience 
       dans la conception et <strong>la commercialisation d'une couverture telle que la garantie mécanique pour les véhicules,</strong> comme la garantie mécanique 
       pour les véhicules, l'assurance des appareils ménagers, la GAP, la PPI et d'autres risques.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      lastPathName: "Secteur des assurances",
    },
    firstBlock: {
      title: "Nos assurances",
      tab1: "maison",
      tab2: "Véhicule",
      tab3: "La vie",
      tab4: "Santé",
      tab5: "Voyage",
    },

    //   here
    secondBlock: {
      title: "Marketing et distribution",
      p1: "Les produits et services de MAPFRE Asistencia peuvent être ajoutés au portefeuille de notre partenaire grâce à <strong> les régimes d'inclusion directe ou distribués en option. </strong>",
      p2: "Afin de faciliter la commercialisation de ces produits, ils sont proposés en mode prestation de services clés en main ou en mode distribution souscrits directement par MAPFRE Asistencia ou notre partenaire, dans ce dernier cas sous une  <strong> système de réassurance. </strong>",
      p3: "Tous les programmes et les polices d'assurance sont conçus de manière à pouvoir être proposés sous la marque de notre partenaire.",
    },
  },
  financialIndustry: {
    title: "Industrie financière",
    secondTitle: "Plus de 30 ans d'expérience dans le secteur financier",
    firstParagraph: `<strong> Notre proposition pour le marché financier </strong> \n \n Nous définissons et gérons en collaboration avec nos partenaires <strong>des programmes visant à accroître la vente et l'activation de produits financiers.</strong> \n
      Nous fournissons également <strong>une couverture d'assurance pour les cartes de crédit et les achats à crédit. </strong> , qui non seulement complètent et favorisent l'utilisation de produits financiers, mais constituent également une nouvelle source de revenus pour nos partenaires.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      lastPathName: "Secteur des assurances",
    },
    firstBlock: {
      title: "Nos assurances",
      tab1: "Banques et institutions financières",
      tab2: "Des programmes conçus pour...",
    },
  },
  insuranceVehiculePage: {
    title: "Industrie  \nautomobile",
    secondTitle:
      "Plus de 30 ans de collaboration avec  \nle secteur automobile",
    firstParagraph: `<strong> Notre proposition pour le marché de l'assurance </strong> \n Nous sommes spécialisés dans l'assistance et dans la conception et la fourniture de <strong>des services personnalisés pour les
       assureurs et leurs portefeuilles d'assurés.</strong>
        \nNous disposons de notre propre réseau d'assistance sur les 5 continents. \nEn tant que souscripteurs 
        nous mettons à la disposition des compagnies d'assurance notre expérience en matière de conception et de <strong>la commercialisation de la couverture 
        comme la garantie mécanique pour les véhicules,</strong> comme la garantie mécanique pour les véhicules, l'assurance des appareils ménagers, le GAP, le PPI et d'autres risques.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      lastPathName: "Industrie automobile",
    },
    //   here
    firstBlock: {
      title: "Notre proposition pour le marché de l'automobile",
      p1: `Nous proposons un portefeuille de produits automobiles complémentaires pour la commercialisation et la distribution. \n 
        Nous sommes des spécialistes des programmes de garantie et de protection mécaniques pour les véhicules neufs et d'occasion. \n 
        Nous proposons également des solutions GAP et PPI pour les achats de véhicules financés, en plus des autres couvertures de véhicules. \n
        Nos solutions sont des compléments perçus comme ayant une forte valeur ajoutée, contribuant à favoriser les décisions d'achat et à augmenter les ventes.
        ainsi qu'à fournir une nouvelle source de revenus à nos partenaires..
        `,
    },
    secondBlock: {
      title: "Lignes d'affaires pour votre entreprise",
      p1: `Nous adaptons nos solutions aux besoins de chaque client : Constructeurs et importateurs de véhicules, banques, sociétés de leasing/location, concessionnaires officiels, distributeurs indépendants, grands distributeurs et distributeurs affiliés, réseaux d'ateliers de réparation et clubs automobiles. `,
    },
    thirdBlock: {
      title: "Nos assurances",
    },
    fourthBlock: {
      title: "Marketing et distribution",
      p1: `Nos produits complémentaires pour véhicules sont fournis clés en main pour une commercialisation directe par 
        le réseau de vente de notre partenaire. Afin d'encourager les ventes, nous avons notre <strong >formation propre et 
        réseau de soutien commercial et marketing pour les ateliers et les concessionnaires </strong> dans le monde entier. \n 
        Tous nos produits peuvent être commercialisés sous la marque de notre partenaire. `,
    },
    fifthBlock: {
      title: "Nous sommes membres du secteur",
      p1: `<strong> Notre centre d'expérimentation et de sécurité routière MAPFRE (CESVIMAP) </strong>  fait des recherches sur tout ce qui concerne les véhicules depuis 1983 et a fait de nous une référence mondiale dans le secteur.`,
    },
  },
  travelTourism: {
    title: "Industrie du voyage et \ndu tourisme",
    secondTitle:
      "Plus de 30 ans de collaboration avec le secteur \ndu voyage et du tourisme",
    firstParagraph: `<strong> Notre proposition pour le marché du voyage et du tourisme </strong> \n \n La plus complète et la plus compétitive <strong>programmes d'assurance voyage et d'assistance aux voyageurs </strong> disponibles pour la commercialisation via les canaux hors ligne et en ligne de nos partenaires. \n\nNous disposons du plus grand réseau d'assistance voyage au monde : avec plus de 31 500 points d'assistance sur les 5 continents, dont des hôpitaux, des centres d'urgence, des ambulances, des ambulances aériennes, etc.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      lastPathName: "Industrie du voyage et du tourisme",
    },

    firstBlock: {
      title: "Nos assurances",
      p1: `Nous adaptons nos solutions aux besoins de chaque client : Compagnies de transport, tour-opérateurs, agences de voyage, organisateurs d'événements, d'expositions, de conférences et de congrès, compagnies de location de voitures, portails en ligne, hôtels, systèmes de relation client et systèmes de distribution globale.
        `,
    },

    secondBlock: {
      title: "Marketing et distribution",
      p1: `Nous proposons des assurances et des services de voyage personnalisés en mode clé en main, prêts à être commercialisés et distribués par le réseau de nos partenaires.
        Nous disposons de notre propre réseau de formation et de soutien commercial et marketing pour <strong >agences de voyage traditionnelles et en ligne </strong> dans le monde entier. 
      `,
    },
    thirdBlock: {
      title: "Remerciements au secteur",
      p1: ` Nous sommes membres affiliés de l'Organisation mondiale du tourisme (OMT). \n Nous avons reçu les prix ITIJ 2005, 2011 et 2013 de la meilleure compagnie d'assurance et d'assistance voyage. `,
    },
  },

  // here:

  //insurances
  homeInsurance: {
    title: `Votre maison. \n Notre monde`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un monde dans lequel vos assurés peuvent bénéficier de la meilleure assistance à domicile. Les garanties les plus avancées pour satisfaire les besoins des clients et leur apporter une totale tranquillité d'esprit.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Votre maison. Notre monde",
    },
    firstBlock: {
      title: "Couvertures",
      coverages: {
        items1: {
          title: "Assistance à domicile",
          description:
            "Un service disponible 24 heures sur 24, 365 jours par an, qui permet de résoudre rapidement toute situation d'urgence dans votre maison et d'éviter ainsi des dommages supplémentaires.",
        },
        items2: {
          title: "Services complémentaires à domicile",
          description:
            "Des services innovants qui complètent la gamme d'assistance à domicile, et qui sont appréciés positivement par les usagers. Il s'agit notamment de la mise en relation ou de la mise à disposition de professionnels, permettant à l'assuré d'accéder à des réseaux de professionnels et de partenaires de tout domaine (technique, réparation, entretien, déménagement, etc.) avec des tarifs attractifs grâce à nos conventions de prestataires.",
        },
      },
    },
  },
  lifeStyle: {
    title: `Votre style de vie. \n Notre monde`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un monde dans lequel la garantie MAPFRE offre une large gamme de polices et de couvertures pour protéger toutes sortes d'objets et d'utilisations de cartes de crédit.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Votre style de vie. Notre monde",
    },
    firstBlock: {
      title: "Produits",
      coverages: {
        items1: {
          title: "Protection des cartes de crédit",
          description:
            "Couvre les achats acquis avec une carte de crédit, en indemnisant l'assuré s'il trouve le produit acquis avec la carte moins cher ailleurs, le vol aux distributeurs automatiques de billets, la détérioration ou le vol d'un produit acquis avec la carte et l'utilisation frauduleuse.",
        },
        items2: {
          title: "Protection des prêts",
          description:
            "Protection de paiement dispensant l'assuré de payer les échéances correspondant à un prêt personnel, à l'achat d'un véhicule ou à un prêt hypothécaire, en cas d'incapacité, de maladie ou de chômage involontaire.",
        },
        items3: {
          title: "Protection du paiement des factures",
          description:
            "Couverture assurant le paiement des factures mensuelles d'électricité, d'eau, de gaz, de téléphone et d'internet, offrant au client une totale tranquillité d'esprit en cas d'accident, de maladie ou de chômage involontaire.",
        },
        items4: {
          title: "Protection des revenus",
          description:
            "Permet à nos partenaires d'offrir à nos clients une tranquillité d'esprit totale, en protégeant leurs familles et leurs modes de vie dans les situations où le travail n'est pas possible.",
        },
        items5: {
          title: "Protection des dispositifs",
          description:
            "Une couverture qui assure la tranquillité d'esprit de nos clients, en protégeant leurs biens de tous les jours tels que les appareils électriques, les équipements informatiques, les téléphones et autres appareils mobiles.",
        },
      },
    },
  },
  road: {
    title: `Votre route. \n Notre monde`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un monde dans lequel MAPFRE Assistance fournit rapidement et efficacement la solution qui convient le mieux à ses clients. Depuis plus de deux décennies, nous concevons des programmes d'assistance routière, identifions les problèmes et développons des services pour devenir les principaux experts du secteur.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Votre route. Notre monde",
    },
    firstBlock: {
      title: "Couvertures",
      coverages: {
        items1: {
          title: "Assistance routière",
          description:
            "Couvre les besoins d'assistance du conducteur, des passagers et du véhicule en cas d'imprévu ou de difficulté rendant impossible la poursuite du voyage à la suite d'un accident ou d'une panne.",
        },
        items2: {
          title: "Services complémentaires d'assistance routière",
          description:
            "Des services personnalisables en fonction des besoins de la compagnie d'assurance, qui visent à élargir leur offre, comme le service de véhicules de remplacement ou le contact ou la mise à disposition de professionnels, et permettent à l'assuré d'accéder à des réseaux de partenaires avec des tarifs attractifs grâce à nos accords de prestataires.",
        },
      },
    },
  },
  car: {
    title: `Votre véhicule. \n Notre monde`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un monde dans lequel MAPFRE Warranty offre la gamme la plus complète de produits et de couvertures pour les marchés des véhicules neufs et d'occasion. De nouvelles lignes d'affaires rentables et des solutions ad hoc qui font de nous un nom de référence dans le secteur.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Votre véhicule. Notre monde",
    },
    firstBlock: {
      title: "Couvertures",
      coverages: {
        items1: {
          title: "Garantie mécanique pour les véhicules",
          description:
            "Un service qui couvre les pannes mécaniques, électriques ou électroniques, qui peuvent survenir avec un véhicule neuf ou d'occasion. iWarranty, c'est aussi une couverture spécialement conçue pour être incluse dans une police d'assurance.",
        },
        items2: {
          title: "Aide à la réparation",
          description:
            "Conseils par téléphone de mécaniciens spécialisés qui évalueront les opérations à effectuer et le coût estimé des réparations, voire négocieront avec l'atelier. Si le client le souhaite, ils peuvent proposer des prestataires alternatifs pour le travail.",
        },
        items3: {
          title: "GAP",
          description:
            "Couverture de la dépréciation ou du prêt à payer sur un véhicule lorsqu'il est radié en raison d'un incendie, d'un vol ou d'un accident.",
        },
      },
    },
  },
  life: {
    title: `Votre vie. \n Notre monde`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Un monde dans lequel vos assurés peuvent compter sur une assistance de qualité dans les meilleurs délais, partout dans le monde.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Votre vie. Notre monde",
    },
    firstBlock: {
      title: "Couvertures",
      coverages: {
        items1: {
          title: "Assistance personnelle",
          description: `Des services innovants qui complètent l'assistance. Deuxième avis médical, conseils par téléphone (juridique, santé, nutrition), services de conciergerie, assistance funéraire, etc.
            Nous permettons également aux assurés d'accéder à des réseaux de professionnels avec des tarifs attractifs grâce à nos accords de prestataires.`,
        },
      },
    },
  },
  travel: {
    title: `Votre voyage. \n Notre monde`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `Dans ce monde, vos assurés pourront profiter du plaisir du voyage en toute sérénité, quelle que soit leur destination, avec la garantie et le renfort
      de la plus large gamme d'assurances voyage.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Votre voyage. Notre monde",
    },
    firstBlock: {
      title: "Couvertures",
      coverages: {
        items1: {
          title: "Assurance voyage",
          description: `Une protection personnelle pour le voyageur et les personnes qui l'accompagnent, pour chaque destination et type de voyage. La couverture est adaptée aux assureurs et à leurs clients : de l'assistance médicale pour couvrir les coûts potentiels liés à un traitement médical coûteux qui peut être nécessaire pendant un voyage, à l'assurance annulation de voyage, à la perte de bagages ou à l'assistance routière.`,
        },
        items2: {
          title: "Assurance annulation de voyage",
          description: `Une assurance qui vous permet de planifier vos voyages à l'avance et qui vous procure une tranquillité d'esprit en cas d'annulation de voyage, de vacances, de billet ou d'événement, en couvrant les dépenses non remboursables d'un assuré.`,
        },
      },
    },
  },

  brandsAssistance: {
    title: `MAPFRE Assistance`,
    secondTitle: "Leader mondial de l'assistance et des services",
    thirdTitle:
      "Nous fournissons une assistance contre l'imprévisible sur la route \net à la maison",
    forthTitle:
      "Ainsi qu'une assistance médicale et de voyage et d'autres services visant à améliorer \nla qualité de vie des personnes.",
    firstParagraph: `<strong> MAPFRE Assistance s'occupe des personnes et des biens partout dans le monde. </strong> \n\nNotre vocation de fournir des services aux personnes partout dans le monde est la philosophie qui détermine la façon dont nous travaillons chez MAPFRE Assistance. Nous collaborons avec nos partenaires depuis plus de 30 ans en augmentant leur volume d'affaires, leurs caractéristiques distinctives et leur rentabilité. Nous proposons des produits et des services innovants qui améliorent la qualité de vie des personnes. C'est notre monde.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Nos partenaires",
      thirdPathName: "MAPFRE ASSISTANCE",
    },
    firstBlock: {
      title: "Lignes de produits MAPFRE Assistance",
      tab1: "Assurance",
      tab2: "Véhicule",
      tab3: "Voyages et tourisme",
      tab4: "Financier",
    },
  },

  brandsWarranty: {
    title: `Garantie MAPFRE`,
    secondTitle:
      "Experts en solutions de garantie, de protection \ndes véhicules et du style de vie",
    thirdTitle:
      "MAPFRE Warranty protège les investissements des particuliers dans les véhicules \n, en offrant une garantie mécanique.",
    forthTitle:
      "programmes, protection des prêts, PPI, GAP et  \nautres couvertures de véhicules.",
    firstParagraph: `La gamme Lifestyle Protection offre également une tranquillité d'esprit en protégeant les articles de tous les jours tels que les appareils électriques \n, les équipements informatiques, les téléphones et autres appareils mobiles.`,
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Nos partenaires",
      thirdPathName: "Garantie Mapfre",
    },
    firstBlock: {
      title: "Lignes de produits de la garantie MAPFRE",
      tab1: "Assurance",
      tab2: "Véhicule",
      tab3: "Voyages et tourisme",
      tab4: "Financier",
    },
    secondBlock: {
      title: "GARANTIE MAPFRE",
      description:
        "Offre également des assurances qui protègent les personnes contre les situations imprévues telles que le chômage, la maladie, l'accident ou d'autres événements imprévus qui affectent la qualité et le style de vie, en assurant le revenu ou le paiement de prêts, de factures, de frais de carte, etc.",
    },
  },
  findUsPage: {
    breadCrumbs: {
      firstPathName: "Accueil",
      lastPathName: "Contactez-nous",
    },
    title: "Présence dans le monde entier",
    secondTitle: "Votre vie. Notre monde",
  },

  claimManagementPage: {
    breadCrumbs: {
      firstPathName: "Accueil",
      secondPathName: "Solutions pour les Entreprises",
      thirdPathName: "Secteur des assurances",
      lastPathName: "Gestion des sinistres - Maison",
    },
    title: "Gestion des sinistres - Maison",
    secondTitle:
      "Un service disponible 24 heures sur 24 pour les compagnies d'assurance qui s'appuie sur l'excellence de MAPFRE Assistance en matière de gestion des sinistres et sur l'expérience acquise depuis plus de 30 ans dans le monde entier.",
    //   heree:
    firstBlock: {
      title: "Couvertures",
      smallTitle: "Gestion des réclamations",
      description:
        " Garantit l'intervention des professionnels de MAPFRE Assistance dans les sinistres couverts par la police de l'assuré avec une portée adaptée aux exigences de l'assureur client. L'assuré n'a qu'à passer un coup de fil. Nous nous chargeons des réparations",
    },
  },
  tunisia: "TUNISIE",

  phoneAssurance: {
    title: "Garantie des téléphones portables",
    smalmTitle: "Garantie contre les bris accidentels",
    breadCrumbs: {
      thirdPathName: "Garantie des téléphones portables",
    },
    description: `La garantie contre les bris accidentels de smartphones est un service qui couvre et prend en charge les coûts de réparation ou de remplacement de l'appareil en cas de dommages causés par des chutes ou des chocs. \n 
      Si votre produit se casse pendant la période de garantie contre la casse, il sera réparé ou remplacé gratuitement ! \n
      La garantie contre les bris accidentels prend effet immédiatement au moment de l'achat de l'appareil et prend fin à la date d'expiration (la 1ère année à compter de la date d'achat).
      `,
    firstTitle: "En cas de bris accidentel, nous offrons :",
    condition1: `Réparation de l'appareil endommagé : comprend le coût de la main-d'œuvre et le prix des pièces détachées`,
    secondTitle:
      "Ou Remplacement par un autre appareil de caractéristiques et de qualité similaires si :",
    condition2: `L'appareil couvert n'est pas réparable`,
    condition3: `Les pièces de rechange ne sont pas disponibles`,
    condition4: `Les coûts de réparation sont plus élevés que le produit lui-même`,
  },
  machineAssurance: {
    title: "Brown & White Guarantee",
    smalmTitle: "Garantie contre les bris accidentels",

    description: `L'extension de garantie Brown & White est un service qui couvre et prend en charge les frais de réparation ou de remplacement de l'appareil en cas de défaut survenant après l'expiration de la garantie du fabricant. \n 
      Si votre produit présente un problème mécanique, électronique ou électrique couvert pendant la période d'extension de garantie, il sera réparé ou remplacé gratuitement ! \n
      L'extension de garantie est souscrite le jour même de l'achat de l'appareil couvert, prend effet à l'expiration de la garantie contractuelle du fabricant et offre les mêmes garanties que le fabricant avec la possibilité de remplacer le produit. \n
      En cas d’une défaillance mécanique, électrique ou électronique survenue  après  l’expiration de la Garantie Constructeur et selon les termes de la garantie, nous proposons :
  
      `,
    firstTitle: "La réparation de l'appareil comprend : ",
    condition1: `coûts de la main-d'œuvre et prix des pièces`,
    secondTitle:
      "Ou Remplacement par un autre appareil de caractéristiques et de qualité similaires si :",
    conclusion1: "L'extension de garantie peut être de 1, 2 ou 3 ans.",
    conclusion2:
      "L'extension de garantie met en valeur le produit et assure la tranquillité d'esprit face aux risques de dépenses imprévues.",
  },

  NEWS: "ACTUALITÉS",
  phone1: "l'administration",
  phone2: "Centre d’appel-Assistance 24/7",
  phone3: "WhatsApp",

  copyRight: {
    title: "Droits d'auteur",
    firstParagraph: `Créée en 1991 AFRIQUE ASSISTANCE est une société Tuniso-espagnole Certifié
    ISO 9001-2008, faisant partie du groupe MAPFRE ASSISTANCE.`,
    secondParagraph: `AFRIQUE ASSISTANCE est leader dans le secteur de l’assistance dans le continent
    africain. Elle dispose d’un réseau complet de fournisseurs pour répondre aux besoins
    quotidiens de sa clientèle. La société propose un réseau de fournisseurs pour :`,
    li1: `Une prise en charge globale : du véhicule, du conducteur et de l’ensemble de
    ses passagers. `,
    li2: `Les prestations d’assistance automobile « des dépanneuses, des véhicules
    ateliers, des agences de location de voiture… »`,
    li3: `Un réseau de fournisseurs pour l’assistance en voyage « taxis, compagnies
    aériennes, hôtels, hôpitaux, médecins, infirmiers et autres professionnels de
    santé, ambulances, avions, sanitaires… »,`,
    li4: `Un réseau de fournisseurs pour l’assistance à domicile « des plombiers, des
    vitriers, des électriciens, des serruriers… » `,
    li5: `un réseau de fournisseurs de garages « Car glass, gestion d’épaves, garages
    conventionnés… ».`,
    li6: `Un réseau de réparateurs « des techniciens, des centres de services
    électroménagers, téléphonie mobile.. »`,
    lastParagraph: `AFRIQUE ASSISTANCE fournit ses services à plusieurs clients corporatifs, dont des
    compagnies d’assurance, des établissements financiers (Banques, sociétés de
    Leasing et des concessionnaires) ainsi que des hypermarchés.`,
  },
};

export const TRANSLATIONS_AR = {
  //navbar
  about: {
    title: "معلومات عنا",
    nav: {
      item0: "معلومات عنا",
      item1: "تاريخنا",
      item2: "الارقام الاساسية",
    },
  },
  solutions: {
    title: "الحلول",
    nav: {
      item0: "حلول الشركات",
      item1: "حلول للأفراد",
    },
  },
  brand: {
    title: "شركاؤنا",
  },
  innovation: {
    title: "أخبار",
  },
  findUs: {
    title: "اتصل بنا",
  },

  // page home

  // first cards

  firstCardTitle: "المساعدة والخدمات",
  firstCardP:
    "نحن نحمي أكثر من 100 مليون شخص في جميع أنحاء العالم ، ونضمن لهم أكثر ما يهم: راحة البال الخاصة بهم وعائلاتهم",
  firstCardTopButton: "حلول الشركات",
  firstCardBottomButton: "الحلول الفردية",

  secondCardTitle: "تأمين السفر",
  secondCardp: "أفضل تأمين سفر مع أكبر \n شبكة مساعدة للمسافرين في العالم.",

  thirdCardTitle: "المساعدة على الطريق",
  thirdCardp:
    "نحن نحمي استثمارات الأشخاص في المركبات ، ونقدم برامج الضمان ، وحماية القروض ، و GAP وتغطية المركبات الأخرى.",

  // mid cards

  midSectionCardFirstCardTitle: "حلول الشركات",
  midSectionCardFirstCardp:
    "المنتجات والخدمات التي تساعد شركائنا على زيادة أعمالهم وتحقيق الفوائد والحماية لعملائهم.",
  midSectionCardFirstCardLink: "الشركات",

  midSectionCardSecondCardTitle: "الحلول الفردية",
  midSectionCardSecondCardp:
    "نحن في متناول اليد لتلبية احتياجاتك واحتياجات أسرتك أينما كنت في العالم. مهمتنا هي ضمان راحة بالك حتى تشعر بالراحة.",
  midSectionCardSecondCardLink: "أفراد",

  midSectionCardThirdCardTitle: "اتصل بنا",
  midSectionCardThirdCardp:
    "حضور مباشر في 33 دولة وخمس قارات ، مما يضمن التميز التشغيلي في أي مكان في العالم.",
  midSectionCardThirdCardLink: "اتصل بنا",
  //home page last section

  homePageLastSectionTitle: "حماية أسلوب الحياة",
  homePageLastSectionParagraph:
    "ضمانات الأجهزة الإلكترونية والأجهزة الكهربائية. \n حماية القروض والمشتريات والتأمينات الأخرى براحة البال.",

  //general ,

  moreInfo: "مزيد من المعلومات",

  //footer
  footer: {
    title: "معلومات اكثر",
    subTitleOne: "حقوق التأليف والنشر Afrique assistance",
    subTitleTwo: "سياسة الخصوصية",
    subTitleThree: "Cookies policy",
    subTitleFour: "Configure cookies",

    copyRight: "Afrique assistance © 2022 جميع الحقوق محفوظة",
  },
  ourHistoryPage: {
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "تاريخنا",
    },

    title: "تاريخنا",
  },

  figures: {
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "الارقام الاساسية",
    },
    title: "الأرقام الرئيسية",
  },
  aboutPage: {
    title: "MAPFRE Asistencia",
    secondTitle: "شركة تأمين وإعادة تأمين \n وخدمات عالمية",
    firstParagraph: ` MAPFRE Asistencia هي شركة تأمين وإعادة تأمين وخدمات عالمية
      تأسست شركة </strong> في مدريد (إسبانيا) عام 1989 وتعمل في جميع أنحاء العالم. نحن
      موجودة مباشرة في <strong> 27 مكتبًا ، </ strong> ولديها أكثر من <strong> 1،300 شركة
      شركاء ، مع أكثر من 100 مليون شخص يستفيدون من
      الخدمات. </ strong>`,

    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "من نحن",
    },

    firstBlock: {
      title: "MAPFRE Asistencia",
      p1: `نحن نقدم منتجات تزيد من ربحية شركائنا وتحسن نوعية حياة الناس. نحن متخصصون في المساعدة العالمية وحماية المركبات وحلول أسلوب الحياة:`,
      p2: `تساعدنا شبكة <strong> المساعدة </ strong> الخاصة بنا في رعاية الأشخاص والممتلكات في جميع أنحاء العالم. نحن نقدم المساعدة في الحوادث غير المتوقعة على جانب الطريق وفي المنزل ، بالإضافة إلى المساعدة الطبية ومساعدة السفر وغيرها من الخدمات التي تهدف إلى تحسين نوعية الحياة.`,
      p3: `نحن نحمي استثمارات الأشخاص في المركبات ، ونقدم <strong> برامج الضمان </ strong> ، والضمان الممتد ، وحماية القروض ، و PPI ، و GAP ، و <strong> تغطية السيارة الأخرى. </ strong> `,
      p4: `توفر منتجات <strong> أسلوب الحياة </strong> الخاصة بنا راحة البال من خلال حماية العناصر اليومية مثل الأجهزة المنزلية وأجهزة الكمبيوتر والهواتف المحمولة أو الأجهزة وبطاقات الائتمان.`,
      p5: `نحن رواد في تقديم الحلول لقطاع التأمين ، بما في ذلك الحلول المحددة للوسطاء ، فضلاً عن القطاعات المالية وصناعة السيارات والسفر والسياحة. نقوم أيضًا بتصميم منتجات مخصصة للشركات في القطاعات الأخرى وللمجموعات الكبيرة.`,
      p6: `بعض منتجاتنا وخدماتنا متاحة للأفراد للشراء مباشرة. `,
    },
    secondBlock: {
      title: "الجوائز والشهادات",
      p1: "تمت مكافأة تميزنا من بين أمور أخرى مع جائزة ITIJ 2005 ، والتي تصدقنا على أننا أفضل شركة مساعدة دولية ، وجوائز ITIJ 2011 و 2013 لأفضل شركة تأمين. لدينا أيضًا شهادة الجودة الدولية ISO 9001.",
    },
  },

  innovationPage: {
    title: "أخبار",
    secondTitle: "ميزة أساسية لـ MAPFRE Asistencia",
    firstParagraph:
      "“الدافع للابتكار لا ينفصل عن جميع جوانب وعمليات شركتنا ويقودنا إلى بناء فرق ، مثل المختبر ، تعمل بكامل طاقتها ، مع متخصصين على استعداد لتولي مسؤولية المشاريع في الأوقات التي قد يكون من المستحيل لأي شخص آخر ، مع الناس قادرة على استخلاص المزيد من أوجه التآزر بشكل أسرع من معظمها ، ومع فرق العمل التي تطرح حلولًا إبداعية.”",
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "أخبار",
    },
  },

  brandsPage: {
    title: "علاماتنا التجارية",
    secondTitle: "مساعدة MAPFRE وضمان MAPFRE",
    firstParagraph:
      "على أي حال ، فإن منتجاتنا وخدماتنا توسع وتكمل العرض التجاري لشركائنا ويمكن تسويقها وتوزيعها تحت علامتهم التجارية الخاصة.",
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "علاماتنا التجارية",
    },
  },

  corporate: {
    title: "حلول  \n الشركات ",
    secondTitle: "أكثر من 30 عامًا في زيادة \n ربحية شركائنا",
    firstParagraph: `نحن نقدم مجموعة من المنتجات والخدمات لحماية الأشخاص والممتلكات التي تغطي الاحتياجات المتزايدة باستمرار. يساعد <strong> تأسيس أو توزيع </ strong> شركائنا لهذه الخدمات والمنتجات والأغلفة على <strong> تنمية أعمالهم وتحسين صورة علامتهم التجارية
      وتعزيز ولاء العملاء. </strong>`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "حلول الشركات",
    },
    firstBlock: {
      title: "حلول",
      item0: {
        title: "حلول لقطاع التأمين",
        description: `لدينا شبكة المساعدة والخدمات الخاصة بنا المصممة خصيصًا لمحافظ المؤمن عليهم في جميع القارات الخمس.
          يقدم وكلاء التأمين لدينا أيضًا تغطية تأمينية لحماية السيارة ونمط الحياة.`,
      },
      item1: {
        title: "حلول لقطاع السيارات",
        description: `نحن متخصصون في تصميم ودعم تسويق وتوزيع برامج الضمان والحماية الميكانيكية للمركبات الجديدة والمستعملة.`,
      },
      item2: {
        title: "حلول لقطاع السياحة والسفر",
        description: `نقدم تأمين السفر وبرامج المساعدة الطبية للتوزيع خارج الإنترنت أو عبر الإنترنت ، وقد تم منحها في عامي 2011 و 2013 مع جائزة ITIJ لأفضل شركة تأمين على السفر وعضو منتسب في منظمة السياحة العالمية (WTO).`,
      },
      item3: {
        title: "حلول للقطاع المالي",
        description: `نقوم بتطوير برامج مبرمجة تهدف إلى زيادة وتفعيل المنتجات المالية ، كما نقدم تغطية تأمينية لبطاقات الائتمان والمشتريات بالتقسيط أو بالتمويل.`,
      },
      item4: {
        title: "ضمان الهاتف المحمول",
        description: `ضمان الكسر العرضي للهاتف الذكي هو خدمة تغطي و
          يتحمل تكلفة إصلاح أو استبدال الجهاز في حال
          الأضرار الناجمة عن السقوط أو الصدمات`,
      },
      item5: {
        title: "ضمان براون اند وايت",
        description: `تمديد الضمان البني الأبيض هو خدمة تغطي وتدعم
          يتحمل تكلفة إصلاح أو استبدال الجهاز في حالة حدوث عطل
          حدثت بعد انتهاء ضمان الشركة المصنعة`,
      },

      moreInfo: "معلومات اكثر",
    },
    secondBlock: {
      title: "و أيضا…",
      description:
        "نصمم منتجات وبرامج مخصصة للشركات والمجموعات لتلبية احتياجات واهتمامات عملائها.",
    },
  },
  ourBrand: {
    title: "علاماتنا التجارية",
    knowThem: "تعرف عليهم",
    firstCard: {
      title: "مساعدة MAPFRE",
      description: "حلول مساعدة شاملة في أي مكان في العالم.",
    },
    secCard: {
      title: "ضمان MAPFRE",
      description: "حلول الضمان وحماية السيارة ونمط الحياة.",
    },
  },
  individual: {
    title: "حلول \n للأفراد ",
    secondTitle: "مصممة لحمايتك وحماية ما يخصك",
    firstParagraph: `هدفنا الوحيد في MAPFRE Asistencia هو ضمان راحة البال لك ولكم أينما كنت. هذا هو السبب في أننا نتطور باستمرار ، ونطور حلولًا تتكيف مع الناس يومًا بعد يوم. لذلك كل ما عليك القلق بشأنه هو الاستمتاع بنفسك دون أي مخاطرة مع الحماية الكاملة لأفضل شركة.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "حلول للأفراد",
    },
    firstBlock: {
      title: "حلول مصممة خصيصا لاحتياجاتك",
      item0: {
        title: "تأمين السفر",
        subItem1: "سافر براحة وأمان مع العلم أن لديك دعمًا من شركة كبرى.",
        subItem2:
          "هل سبق لك أن فقدت أمتعتك على متن رحلة؟ هل اضطررت من قبل إلى إلغاء رحلة في اللحظة الأخيرة؟ إذا كانت لديك أي مشكلة أو كنت بحاجة إلى مساعدة طبية أثناء رحلتك ، فهل تعرف إلى أين تتجه؟",
        subItem3:
          "اضمن راحة بالك وراحة زملائك المسافرين مع أفضل تأمين سفر متوفر في السوق.",
        link1: "Segurviaje.com",
      },
      item1: {
        title: "حماية لسيارتك",
        subItem1:
          "يحمي سيارتك من الأعطال الميكانيكية أو الكهربائية أو الإلكترونية بعد فترة الضمان الرسمية.",
        subItem2:
          "ماذا يحدث إذا تعطلت سيارتك ولم تكن مشمولة بضمان الشركة المصنعة؟ ماذا لو تعرضت لحادث؟",
        subItem3:
          "احمِ نفسك من هذه الأحداث وغيرها التي يمكن أن تؤدي إلى ارتفاع النفقات غير المتوقعة.",
        link1: "مزيد من المعلومات عن إيطاليا",
        link2: "مزيد من المعلومات حول تركيا",
      },
      item2: {
        title: "حماية أسلوب الحياة",
        subItem1: "حلول لحماية كل ما يحيط بك في يومك ليومك.",
        subItem2:
          "احمِ هاتفك المحمول وأجهزتك المنزلية وجميع الأجهزة الأخرى التي تستخدمها يوميًا ضد الأعطال.",
        subItem3:
          "احمِ أسلوب حياتك أيضًا من المواقف الشخصية التي قد تؤثر عليك.",
      },
    },
  },

  solutionsCards: {
    card1: {
      name: "طريقك. عالمنا",
      description: `خدمة المساعدة على الطريق\n
        خدمات تكميلية على جانب الطريق`,
    },
    card2: {
      name: "سفرك. عالمنا",
      description: `تأمين السفر\n
        تأمين إلغاء السفر`,
    },
    card3: {
      name: "سيارتك. عالمنا",
      description: `الضمان الميكانيكي للمركبات\n
        مساعدة الإصلاح\n
          GAP`,
    },
    card4: {
      name: "أسلوب حياتك. عالمنا",
      description: `حماية بطاقة الائتمان.\n
        حماية القرض.\n
        حماية دفع الفواتير.\n
        حماية الدخل.\n
        حماية الأجهزة.`,
    },
    card5: {
      name: "منزلك. عالمنا",
      description: `المساعدة في المنزل\n
        خدمات منزلية تكميلية`,
    },
    card6: {
      name: "حياتك. عالمنا",
      description: `المساعدة الشخصية`,
    },
    // Programmes desgned for
    card7: {
      name: "العملاء الأفراد والشركات",
      description: `<strong>أفراد</strong>\n\n  الممتازة \n\n  اساسي\n \n<strong>شركات</strong> \n\n كبير \n\n متوسط \n\n صغير `,
    },
    card8: {
      name: "منتجات",
      description: `الحسابات الجارية \n \n حسابات التوفير \n \n بطاقات الخصم  \n \n بطاقات الائتمان \n \n قروض الرهن العقاري \n\n رصيد المستهلك \n \n  خطط الاستثمار \n  \n خطط التقاعد`,
    },
    card9: {
      name: "قنوات التوزيع",
      description: `مكاتب \n \n أجهزة الصراف الآلي \n \n متصل \n \n التسويق عبر الهاتف \n \n مركز الاتصال \n \n البريد المباشر \n \n البريد الإلكتروني \n \n إدراجات \n \n وسائل التواصل الاجتماعي`,
    },
    card10: {
      name: "إدارة المطالبات",
      description: `إدارة المطالبات`,
    },
  },
  // Solutions for the قطاع التأمين
  insuranceIndustryPage: {
    title: "قطاع التأمين",
    secondTitle: "أكثر من 30 عامًا في زيادة\n ربحية التأمين والشركات",
    firstParagraph: `<strong> اقتراحنا لسوق التأمين </ strong> \n نحن متخصصون في المساعدة وتصميم وتقديم <strong> خدمات مخصصة لشركات التأمين ومحافظهم من المؤمن عليهم. </ strong> \n لدينا شبكة المساعدة الخاصة بنا على جميع القارات الخمس. \n بصفتنا مكتتبين ، نقدم لشركات التأمين خبرتنا في تصميم و <strong> تسويق التغطية مثل الضمان الميكانيكي للمركبات ، </ strong> مثل الضمان الميكانيكي للمركبات ، والتأمين على الأجهزة المنزلية ، و GAP ، و PPI ، والمخاطر الأخرى.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      lastPathName: "قطاع التأمين",
    },
    firstBlock: {
      title: "تأميناتنا",
      tab1: "مسكن",
      tab2: "السيارة",
      tab3: "حياة",
      tab4: "صحة",
      tab5: "السفر",
    },
    secondBlock: {
      title: "التسويق والتوزيع",
      p1: "يمكن إضافة منتجات وخدمات MAPFRE Asistencia إلى محفظة شركائنا من خلال <strong> مخططات التضمين المباشر أو توزيعها كخيار. </strong>",
      p2: "للمساعدة في تسويق هذه المنتجات ، يتم تقديمها في وضع توفير الخدمة بنظام تسليم المفتاح أو في وضع التوزيع الذي يتم الاكتتاب فيه مباشرةً بواسطة MAPFRE Asistencia أو شريكنا ، في هذه الحالة الأخيرة بموجب <strong> مخطط إعادة التأمين. </strong>",
      p3: "تم تصميم جميع البرامج وبوالص التأمين بحيث يمكن تقديمها تحت العلامة التجارية لشريكنا.",
    },
  },
  financialIndustry: {
    title: "القطاع المالي",
    secondTitle: "أكثر من 30 عامًا من العمل في القطاع المالي",
    firstParagraph: `<strong> اقتراحنا للسوق المالي </ strong> \n \n نحدد ونعمل بالتعاون مع شركائنا <strong> البرامج التي تهدف إلى زيادة بيع وتفعيل المنتجات المالية. </ strong> \n
      نوفر أيضًا <strong> غطاء تأمينيًا لبطاقات الائتمان والمشتريات بالائتمان </ strong> ، والتي لا تكمل وتفضل استخدام المنتجات المالية فحسب ، ولكنها تشكل أيضًا مصدرًا جديدًا للدخل لشركائنا.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      lastPathName: "قطاع التأمين",
    },
    firstBlock: {
      title: "تأميناتنا",
      tab1: "البنوك والمؤسسات المالية",
      tab2: "برامج مصممة لـ ...",
    },
  },
  insuranceVehiculePage: {
    title: "صناعة \n السيارات ",
    secondTitle: "أكثر من 30 عامًا في التعاون مع \n قطاع السيارات",
    firstParagraph: `<strong> اقتراحنا لسوق التأمين </ strong> \n نحن متخصصون في المساعدة وتصميم وتقديم <strong> خدمات مخصصة لشركات التأمين ومحافظهم من المؤمن عليهم. </ strong> \n لدينا شبكة المساعدة الخاصة بنا على جميع القارات الخمس. \n بصفتنا مكتتبين ، نقدم لشركات التأمين خبرتنا في تصميم و <strong> تسويق التغطية مثل الضمان الميكانيكي للمركبات ، </ strong> مثل الضمان الميكانيكي للمركبات ، والتأمين على الأجهزة المنزلية ، و GAP ، و PPI ، والمخاطر الأخرى.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      lastPathName: "صناعة السيارات",
    },

    firstBlock: {
      title: "عرضنا لسوق السيارات",
      p1: `نحن نقدم مجموعة من منتجات المركبات التكميلية للتسويق والتوزيع. \n 
        نحن متخصصون في <strong> برامج الحماية والضمان الميكانيكي للسيارات الجديدة والمستعملة. </ strong> \n 
        نقدم أيضًا حلول GAP و PPI لشراء السيارات الممولة ، بالإضافة إلى تغطية السيارة الأخرى. \n
        حلولنا هي مكملات يُنظر إليها على أنها ذات قيمة مضافة عالية ، مما يساعد على تفضيل قرارات الشراء وزيادة المبيعات ، فضلاً عن توفير مصدر جديد للإيرادات لشركائنا.
        `,
    },
    secondBlock: {
      title: "خطوط الأعمال لشركتك",
      p1: `نقوم بتكييف حلولنا مع احتياجات كل عميل: مصنعي ومستوردي المركبات ، البنوك ، شركات التأجير / التأجير ، الوكلاء الرسميين ، الموزعين المستقلين ، الموزعين الرئيسيين والموزعين التابعين ، شبكات ورش عمل الإصلاح ونوادي السيارات. `,
    },
    thirdBlock: {
      title: "تأميناتنا",
    },
    fourthBlock: {
      title: "التسويق والتوزيع",
      p1: `يتم توفير منتجاتنا التكميلية للمركبات بنظام تسليم المفتاح للتسويق المباشر من خلال شبكة مبيعات شركائنا. من أجل تشجيع المبيعات ، لدينا تدريب <قوي> خاص بنا وشبكة دعم تجاري وتسويقي لورش العمل والوكلاء </ strong> في جميع أنحاء العالم. \n
        يمكن تسويق جميع منتجاتنا تحت العلامة التجارية لشريكنا. `,
    },
    fifthBlock: {
      title: "نحن أعضاء في القطاع",
      p1: `لقد بحث <strong> مركز MAPFRE للسلامة على الطرق والتجريب (CESVIMAP) </strong> في كل ما يتعلق بالمركبات منذ عام 1983 وجعلنا مرجعًا عالميًا في هذا القطاع.`,
    },
  },
  travelTourism: {
    title: "صناعة السفر و \n السياحة",
    secondTitle: "أكثر من 30 عامًا من التعاون مع قطاع السفر \n والسياحة",
    firstParagraph: `<strong> عرضنا لسوق السفر والسياحة </ strong> \n \n <strong> برامج تأمين السفر ومساعدة المسافرين </ strong> الأكثر شمولاً وتنافسية والمتاحة للتسويق من خلال قنوات شركائنا عبر الإنترنت وغير المتصلة بالإنترنت. \n \n لدينا أكبر شبكة مساعدة للسفر في العالم: مع أكثر من 31500 نقطة مساعدة في جميع القارات الخمس ، بما في ذلك المستشفيات ومراكز الطوارئ وسيارات الإسعاف والإسعاف الجوي وما إلى ذلك.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      lastPathName: "صناعة السفر والسياحة",
    },

    firstBlock: {
      title: "تأميناتنا",
      p1: `نقوم بتكييف حلولنا مع احتياجات كل عميل: شركات النقل ومنظمي الرحلات السياحية ووكالات السفر ومنظمي الأحداث والمعارض والمؤتمرات والمؤتمرات وشركات تأجير السيارات والبوابات الإلكترونية والفنادق ونظام علاقات العملاء ونظام التوزيع العالمي.
        `,
    },

    secondBlock: {
      title: "التسويق والتوزيع",
      p1: `نحن نقدم خدمات تأمين السفر المخصصة في وضع تسليم المفتاح ، وجاهزة للتسويق والتوزيع من خلال شبكة شركائنا.
        لدينا شبكة دعم تدريبية وتجارية وتسويقية خاصة بنا لوكالات السفر التقليدية وعبر الإنترنت </ strong> في جميع أنحاء العالم. 
      `,
    },
    thirdBlock: {
      title: "إقرارات القطاع",
      p1: ` نحن أعضاء منتسبون إلى منظمة السياحة العالمية (WTO). \n لقد حصلنا على جوائز ITIJ 2005 و 2011 و 2013 لأفضل شركة تأمين ومساعدة للسفر.`,
    },
  },

  //insurances
  homeInsurance: {
    title: `منزلك. \n عالمنا`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `عالم يمكن فيه لمؤمنيك التمتع بأفضل مساعدة منزلية. الغطاء الأكثر تقدمًا لتلبية احتياجات العملاء وتحقيق راحة البال الكاملة لهم.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "منزلك. عالمنا",
    },
    firstBlock: {
      title: "التغطيات",
      coverages: {
        items1: {
          title: "المساعدة المنزلية",
          description:
            "الخدمة متاحة على مدار 24 ساعة في اليوم ، 365 يومًا في السنة والتي تحل بسرعة أي حالة طارئة في منزلك وبالتالي تجنب المزيد من الضرر.",
        },
        items2: {
          title: "خدمات منزلية تكميلية",
          description:
            "الخدمات المبتكرة التي تكمل نطاق المساعدة المنزلية ، والتي تحظى بتقدير إيجابي من قبل المستخدمين. يتضمن ذلك الاتصال أو توفير المهنيين ، وتوفير الوصول المؤمن إلى شبكات المهنيين والشركاء من أي مجال (فني ، إصلاحات ، صيانة ، إزالة ، إلخ) بأسعار مغرية بفضل اتفاقيات مزودنا.",
        },
      },
    },
  },
  lifeStyle: {
    title: `أسلوب حياتك \n عالمنا`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `عالم يقدم فيه ضمان MAPFRE مجموعة واسعة من السياسات والتغطية لحماية جميع أنواع الأشياء واستخدام بطاقات الائتمان`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "أسلوب حياتك. عالمنا",
    },
    firstBlock: {
      title: "المنتجات",
      coverages: {
        items1: {
          title: "حماية بطاقة الائتمان",
          description:
            "تغطي المشتريات التي تم الحصول عليها ببطاقة ائتمان ، وتعويض المؤمن عليه في حالة العثور على المنتج الذي تم الحصول عليه بالبطاقة أرخص في مكان آخر ، أو السرقة في آلات صرف النقود ، أو تلف أو سرقة منتج تم الحصول عليه باستخدام البطاقة والاستخدام الاحتيالي.",
        },
        items2: {
          title: "حماية القرض",
          description:
            "حماية الدفع التي تعفي المؤمن عليه من سداد الأقساط المقابلة لقرض شخصي أو شراء سيارة أو قرض رهن عقاري في حالة العجز أو المرض أو البطالة غير الطوعية.",
        },
        items3: {
          title: "حماية دفع الفواتير",
          description:
            "تغطية تأمينية ضد سداد فواتير الكهرباء والمياه والغاز والهاتف والإنترنت الشهرية ، مما يمنح العميل راحة البال الكاملة في حالة وقوع حادث أو مرض أو بطالة قسرية.",
        },
        items4: {
          title: "حماية الدخل",
          description:
            "يتيح لشركائنا تقديم راحة بال تامة لعملائنا ، وحماية عائلاتهم وأنماط حياتهم في المواقف التي لا يكون فيها العمل ممكنًا.",
        },
        items5: {
          title: "حماية الأجهزة",
          description:
            "التغطية التي توفر لعملائنا راحة البال ، وتحمي أغراضهم اليومية مثل الأجهزة الكهربائية ، وتقنية المعلومات. المعدات والهواتف والأجهزة المحمولة الأخرى.",
        },
      },
    },
  },
  road: {
    title: `طريقك. \n عالمنا`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `عالم تقدم فيه MAPFRE Assistance بسرعة وفعالية الحل الأنسب لعملائها. لقد قمنا بتصميم برامج المساعدة على الطريق لأكثر من عقدين من الزمن ، وتحديد المشاكل وتطوير الخدمات لنصبح خبراء رائدين في هذا القطاع.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "طريقك. عالمنا",
    },
    firstBlock: {
      title: "التغطيات",
      coverages: {
        items1: {
          title: "المساعدة على الطريق",
          description:
            "يغطي احتياجات المساعدة للسائق والركاب والمركبة في حالة حدوث أي طارئ أو صعوبة تجعل من المستحيل استمرار الرحلة بعد وقوع حادث أو عطل.",
        },
        items2: {
          title: "خدمات تكميلية على جانب الطريق",
          description:
            "الخدمات التي يمكن تخصيصها وفقًا لمتطلبات شركة التأمين ، والتي تم تصميمها لتوسيع نطاق عرضها ، مثل خدمة السيارة البديلة أو الاتصال أو توفير المهنيين ، والسماح للمؤمن بالوصول إلى شبكات الشركاء بأسعار مغرية بفضل اتفاقيات المزود لدينا .",
        },
      },
    },
  },
  car: {
    title: `سيارتك \n عالمنا`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `عالم يقدم فيه ضمان MAPFRE مجموعة كاملة من المنتجات والتغطية لأسواق السيارات الجديدة والمستعملة. خطوط أعمال جديدة مربحة وحلول مخصصة تجعلنا اسمًا مرجعيًا في هذا القطاع.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "سيارتك. عالمنا",
    },
    firstBlock: {
      title: "التغطيات",
      coverages: {
        items1: {
          title: "الضمان الميكانيكي للمركبات",
          description:
            "خدمة تغطي الأعطال الميكانيكية أو الكهربائية أو الإلكترونية التي قد تحدث مع مركبة جديدة أو مستعملة. الضمان ، هو أيضًا تغطية مصممة خصيصًا لتضمينها في بوليصة التأمين.",
        },
        items2: {
          title: "مساعدة الإصلاح",
          description:
            "التوجيه عبر الهاتف من الميكانيكيين المتخصصين الذين سيقيمون العمليات التي سيتم إجراؤها والتكلفة التقديرية للإصلاحات ، وحتى التفاوض مع ورشة العمل. إذا رغب العميل في ذلك ، فيمكنه اقتراح مزودي بديل للوظيفة.",
        },
        items3: {
          title: "GAP",
          description:
            "التغطية ضد الانخفاض في القيمة أو القرض المستحق الدفع على السيارة عند شطبها بسبب حريق أو سرقة أو حادث.",
        },
      },
    },
  },
  life: {
    title: `حياتك. \n عالمنا`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `عالم يمكن فيه لمؤمنيك الاعتماد على جودة المساعدة بأسرع وقت ممكن في أي مكان في العالم`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "حياتك. عالمنا",
    },
    firstBlock: {
      title: "التغطيات",
      coverages: {
        items1: {
          title: "المساعدة الشخصية",
          description: `خدمات مبتكرة تكمل المساعدة. رأي طبي ثاني ، إرشاد عبر الهاتف (قانوني ، صحي ، تغذوي) ، خدمات الكونسيرج ، المساعدة في الجنازة ، إلخ.
            كما نوفر للمؤمن عليهم إمكانية الوصول إلى شبكات المهنيين بأسعار مغرية بفضل اتفاقيات المزود الخاصة بنا.`,
        },
      },
    },
  },
  travel: {
    title: `سفرك. \n عالمنا`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `في هذا العالم ، سيتمكن المؤمن عليهم من الاستمتاع بمتعة السفر براحة بال تامة مهما كانت وجهتهم ، مع الضمان والدعم.
      من أوسع مجموعة من تأمينات السفر.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "سفرك. عالمنا",
    },
    firstBlock: {
      title: "التغطيات",
      coverages: {
        items1: {
          title: "تأمين السفر",
          description: `الحماية الشخصية للمسافر والأشخاص المرافقين لكل وجهة ونوع السفر. التغطية مصممة لشركات التأمين وعملائها: من المساعدة الطبية لتغطية التكاليف المحتملة المرتبطة بالعلاج الطبي الباهظ الذي يمكن أن تكون مطلوبة أثناء الرحلة ، إلى تأمين إلغاء السفر ، وفقدان الأمتعة ، أو المساعدة على الطريق.`,
        },
        items2: {
          title: "تأمين إلغاء السفر",
          description: `التأمين الذي يسمح لك بالتخطيط لرحلاتك مسبقًا ويوفر راحة البال في حالة إلغاء الرحلة أو العطلة أو التذكرة أو الحدث ، ويغطي نفقات المؤمن غير القابلة للاسترداد.`,
        },
      },
    },
  },

  brandsAssistance: {
    title: `MAPFRE Assistance`,
    secondTitle: "الرائد عالميًا في المساعدة والخدمات",
    thirdTitle:
      "نحن نقدم المساعدة ضد ما لا يمكن توقعه على الطريق \n وفي المنزل",
    forthTitle:
      "بالإضافة إلى السفر والمساعدة الطبية والخدمات الأخرى \n التي تهدف إلى تحسين نوعية حياة الناس",
    firstParagraph: `<strong> مساعدة MAPFRE تعتني بالأشخاص والممتلكات في أي مكان في العالم. </strong> \n \n مهمتنا لتقديم الخدمات للأشخاص في أي مكان في العالم هي الفلسفة التي تحدد كيفية عملنا في MAPFRE Assistance. لقد تعاونا مع شركائنا لأكثر من 30 عامًا لزيادة حجم أعمالهم ، وميزات مميزة وربحية. نحن نقدم منتجات وخدمات مبتكرة تعمل على تحسين نوعية حياة الناس. هذا هو عالمنا.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "علاماتنا التجارية",
      thirdPathName: "MAPFRE ASSISTANCE",
    },
    firstBlock: {
      title: "خطوط إنتاج MAPFRE المساعدة",
      tab1: "تأمين",
      tab2: "السيارة",
      tab3: "سياحة و سفر",
      tab4: "الأمور المالية",
    },
  },

  brandsWarranty: {
    title: `MAPFRE Warranty`,
    secondTitle: "خبراء في الضمان والمركبات وأسلوب الحياة \n حلول الحماية",
    thirdTitle:
      "يحمي ضمان MAPFRE استثمارات الأشخاص \n تسع مركبات ، ويقدم ضمانًا ميكانيكيًا",
    forthTitle:
      "البرامج ، وحماية القروض ، و PPI ، و GAP ، وتغطية أخرى \n للمركبات.",
    firstParagraph: `يوفر نطاق أسلوب الحياة أيضًا راحة البال لحماية العناصر اليومية \n مثل الأجهزة الكهربائية و I.T. المعدات والهواتف والأجهزة المحمولة الأخرى.`,
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "علاماتنا التجارية",
      thirdPathName: "Mapfre Warranty",
    },
    firstBlock: {
      title: "خطوط منتجات الضمان MAPFRE",
      tab1: "تأمين",
      tab2: "السيارة",
      tab3: "سياحة و سفر",
      tab4: "الأمور المالية",
    },
    secondBlock: {
      title: "ضمان MAPFRE",
      description:
        "يقدم أيضًا تأمينًا يحمي الأشخاص من المواقف غير المتوقعة مثل البطالة أو المرض أو الحوادث أو غيرها من الأحداث غير المتوقعة التي تؤثر على نوعية الحياة وأسلوبها أو تأمين الدخل أو سداد القروض والفواتير ورسوم البطاقات وما إلى ذلك.",
    },
  },
  findUsPage: {
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      lastPathName: "اتصل بنا",
    },
    title: "حضور عالمي",
    secondTitle: "حياتك. عالمنا",
  },

  claimManagementPage: {
    breadCrumbs: {
      firstPathName: "الصفحه الرئيسيه",
      secondPathName: "حلول الشركات",
      thirdPathName: "قطاع التأمين",
      lastPathName: "إدارة المطالبات - المنزل",
    },
    title: "الصفحة الرئيسية لإدارة المطالبات",
    secondTitle:
      "خدمة على مدار 24 ساعة متاحة لشركات التأمين والتي تعتمد على تميز MAPFRE Assistance في إدارة المطالبات والخبرة المكتسبة على مدى 30 عامًا في جميع أنحاء العالم.",

    firstBlock: {
      title: "التغطيات",
      smallTitle: "إدارة الدعاوى",
      description:
        "يضمن تدخل المتخصصين في مساعدة MAPFRE في المطالبات التي تغطيها سياسة المؤمن له مع النطاق المصمم وفقًا لمتطلبات شركة التأمين الخاصة بالعميل. يجب على المؤمن له فقط إجراء مكالمة هاتفية. نحن نهتم بالإصلاحات.",
    },
  },
  tunisia: "تونس",

  phoneAssurance: {
    title: "ضمان الهاتف المحمول",
    smalmTitle: "ضمان الكسر",
    breadCrumbs: {
      thirdPathName: "ضمان الهاتف المحمول",
    },
    description: `ضمان الكسر الناتج عن الهاتف الذكي هو خدمة تغطي وتتحمل تكاليف إصلاح الجهاز أو استبداله في حالة التلف الناتج عن السقوط أو الصدمات. \n
      إذا تعطل منتجك خلال فترة ضمان الكسر ، فسيتم إصلاحه أو استبداله مجانًا! \n
      يسري ضمان الكسر العرضي فور شراء الجهاز وينتهي في تاريخ انتهاء الصلاحية (السنة الأولى من تاريخ الشراء)
      `,
    firstTitle: "في حالة الكسر العرضي نقدم:",
    condition1: `إصلاح الجهاز التالف: ويشمل تكلفة العمالة وسعر القطع`,
    secondTitle: "أو الاستبدال بجهاز آخر له نفس الخصائص والجودة إذا:",
    condition2: `الجهاز المغطى غير قابل للإصلاح`,
    condition3: `قطع الإصلاح غير متوفرة`,
    condition4: `تكاليف الإصلاح تكلف أكثر من المنتج نفسه`,
  },
  machineAssurance: {
    title: "ضمان براون اند وايت",
    smalmTitle: "Accidental Breakage Guarantee",

    description: `تمديد الضمان البني والأبيض هو خدمة تغطي وتتحمل تكلفة إصلاح أو استبدال الجهاز في حالة حدوث عطل بعد انتهاء ضمان الشركة المصنعة. \n 
      إذا واجه منتجك مشكلة ميكانيكية أو إلكترونية أو كهربائية مغطاة أثناء فترة الضمان الممتد ، فسيتم إصلاحه أو استبداله مجانًا! \n
      يسري تمديد الضمان في نفس يوم شراء الجهاز المغطى ، ويسري عند انتهاء الضمان التعاقدي للشركة المصنعة ويقدم نفس الضمانات التي توفرها الشركة المصنعة مع إمكانية استبدال المنتج. \n
      في حالة حدوث عطل ميكانيكي أو كهربائي أو إلكتروني بعد انتهاء ضمان الشركة المصنعة ووفقًا لشروط الضمان ، فإننا نقدم:
      `,
    firstTitle: "يشمل إصلاح الجهاز : ",
    condition1: `تكاليف العمالة وأسعار قطع الغيار`,
    secondTitle: "أو الاستبدال بجهاز آخر له نفس الخصائص والجودة إذا: ",
    conclusion1: "يمكن أن يكون تمديد الضمان سنة أو سنتين أو ثلاث سنوات",
    conclusion2:
      "يعزز الضمان الممتد المنتج ويوفر راحة البال من مخاطر النفقات غير المتوقعة",
  },

  NEWS: "الاخبار",
  phone1: "الادارة",
  phone2: "مركز الاتصال - دعم 24/7",
  phone3: "WhatsApp",
  copyRight: {
    title: "حقوق النشر",
    firstParagraph: `تم إنشاء AFRIQUE ASSISTANCE في عام 1991 وهي شركة تونسية إسبانية معتمدة
    ISO 9001-2008 ، جزء من مجموعة MAPFRE ASSISTANCE.`,
    secondParagraph: `AFRIQUE ASSISTANCE هي شركة رائدة في قطاع المساعدة في القارة
    الأفريقي. لديها شبكة شاملة من الموردين لتلبية الاحتياجات
    الحياة اليومية لعملائها. تقدم الشركة شبكة من الموردين من أجل:`,
    li1: `رعاية شاملة: للمركبة والسائق وكل من
    ركابها. `,
    li2: `خدمات مساعدة السيارات "لشاحنات القطر والمركبات
    ورش العمل ووكالات تأجير السيارات ... "`,
    li3: `شبكة من الموردين للمساعدة في السفر “سيارات الأجرة والشركات
    شركات الطيران والفنادق والمستشفيات والأطباء والممرضات وغيرهم من المتخصصين في الرعاية الصحية
    الصحة ، سيارات الإسعاف ، الطائرات ، المراحيض ... "،`,
    li4: `شبكة من الموردين للمساعدة المنزلية "سباكين ،
    زجاج ، كهربائيون ، صانعو أقفال ... "`,
    li5: `شبكة من موردي المرآب "زجاج السيارات ، إدارة الحطام ، الجراجات
    تعاقدت ... ".`,
    li6: `شبكة من المصلحين "الفنيين ومراكز الخدمة
    الأجهزة المنزلية والهواتف المحمولة وما إلى ذلك.`,
    lastParagraph: `تقدم AFRIQUE ASSISTANCE خدماتها للعديد من العملاء من الشركات ، بما في ذلك
    شركات التأمين والمؤسسات المالية (البنوك وشركات التأمين ،
    التأجير وأصحاب الامتياز) وكذلك محلات السوبر ماركت.`,
  },
};

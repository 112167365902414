import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./solutions.css";
import Solutions from "../../components/solutions/Solutions";
import OurBrands from "../../components/ourBrands/OurBrands";
import TextWithCheckBox from "../../components/cards/TextWithCheckBox";
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from "react-router-dom";


export default function Individuals() {
  const { t } = useTranslation();

  const individualsData = [
    {
      name: t("individual.firstBlock.item0.title"),
      links: [
        { name: t("individual.firstBlock.item0.link1"), path: "https://www.segurviaje.com/" }
      ],
      subCards: [
        t("individual.firstBlock.item0.subItem1"),
        t("individual.firstBlock.item0.subItem2"),
        t("individual.firstBlock.item0.subItem3"),

      ]

    },
    {
      name: t("individual.firstBlock.item1.title"),
      subCards: [
        t("individual.firstBlock.item1.subItem1"),
        t("individual.firstBlock.item1.subItem2"),
        t("individual.firstBlock.item1.subItem3"),

      ],
      links: [
        { name: t("individual.firstBlock.item1.link1"), path: "https://www.mapfre-warranty.com/webItaliaComercial/login.aspx" },
        { name: t("individual.firstBlock.item1.link2"), path: "https://www.turassist.com/cgi-sys/defaultwebpage.cgi" },

      ],

    },
    {
      name: t("individual.firstBlock.item2.title"),
      links: [],
      subCards: [
        t("individual.firstBlock.item2.subItem1"),
        t("individual.firstBlock.item2.subItem2"),
        t("individual.firstBlock.item2.subItem3"),
      ]
    },


  ];

  return (
    <div className="aboutPage">
      <div className="aboutPageBg individuals">
        <h1>{t("individual.title")}</h1>
        <h3>{t("individual.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("individual.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("individual.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle">
        {t("individual.firstParagraph")}
      </div>

      <div className="block">
        <h1 className="underLinedTitle">{t("individual.firstBlock.title")}</h1>
        <div className="solutionsCards indivs">

          {
            individualsData.map(elm => (
              <div className="solutionCard individuals">
                <h3>{elm.name}</h3>

                {elm.subCards.map(card => (
                  <TextWithCheckBox text={card} />
                ))}
{/* 
                {
                  elm.links.map(link => (
                    <a target="_blank" href={`${link.path}`} > {link.name} <ChevronRightIcon className="w-4 ml-1 cursor-pointer" /></a>

                  ))
                } */}


              </div>
            ))
          }

        </div>

      </div>

    </div>
  );
}

import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Tabs from "../../components/tabs/Tabs";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/outline";

export default function TravelTourismInsurance() {
  const { t } = useTranslation();

  let data =  [

      {
        icon: "/images/icons/travel2.PNG",

     
        name: t("solutionsCards.card2.name"),
        description: t("solutionsCards.card2.description"),
        link: "/corporate-solutions/insurance/your-travel",
      },
      {
        icon: "/images/icons/car2.PNG",
        name: t("solutionsCards.card1.name"),
        description: t("solutionsCards.card1.description"),
        link: "/corporate-solutions/insurance/insurance-road",
      },
      {
        icon: "/images/icons/road2.PNG",

        name: t( "solutionsCards.card4.name"),
        name: t("solutionsCards.card4.name"),
        description: t("solutionsCards.card4.description"),
        link: "/corporate-solutions/insurance/your-lifestyle/",
      },
  ]

  return (
    <div className="aboutPage">
      <div className="aboutPageBg travelTourism">
        <h1>{t("travelTourism.title")}</h1>
        <h3>{t("travelTourism.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("travelTourism.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("travelTourism.breadCrumbs.secondPathName"),
              type: "link",
              to: "/corporate-solutions",
            },
            {
              pathname: t("travelTourism.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="travelTourism.firstParagraph"></Trans>
      </div>
  
  

      <div className="block">
        <h1 className="underLinedTitle">
          {t("travelTourism.firstBlock.title")}
        </h1>
        <p>
          <Trans i18nKey="travelTourism.firstBlock.p1"></Trans>
        </p>
        <div className="solutionsCards">
        {
            data.map(elm=> (
                <div className="solutionCard">
                    <img src={elm.icon} alt={elm.name} />
                    <h3>{elm.name}</h3>
                    <p style={{lineHeight : "initial" }}>{elm.description}</p>
                    <Link to={elm.link} > {t("corporate.firstBlock.moreInfo")} <ChevronRightIcon className="w-4 ml-1 cursor-pointer" /></Link>
                </div>
            ))
        }

      </div>
      </div>
   
      <div className="block">
        <h1 className="underLinedTitle">
          {t("travelTourism.secondBlock.title")}
        </h1>
        <p>
          <Trans i18nKey="travelTourism.secondBlock.p1"></Trans>
        </p>
      </div>

      <div className="block">
        <h1 className="underLinedTitle">
          {t("travelTourism.thirdBlock.title")}
        </h1>
        <p>
          <Trans i18nKey="travelTourism.thirdBlock.p1"></Trans>
        </p>
      </div>
      
    </div>
  );
}

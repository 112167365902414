import React from "react";
import TextWithCheckBox from "../cards/TextWithCheckBox";

export default function checkedItem({title , description}) {
  return (
    <div className="checkItem">
      <TextWithCheckBox text={title}/>
      <p>
        {description}
      </p>
    </div>
  );
}

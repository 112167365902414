import "./header.css";
import EastIcon from "@mui/icons-material/East";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import i18n, { t } from "i18next";
import ListMenu from "../menu/Menu";
import { Link } from "react-router-dom"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default function Header(props) {
  const [lng, setLng] = useState({ value: "es", label: "ES" });
  const [isMenuOpen, setMenu] = useState(null);
  const [isResponsiveOpen, setResponsiveOpen] = useState(false);
  const [collapseIndex, setCollapseIndex] = useState(null);
  const [search, setSearch] = useState("")
  const [isSearchable, toggleSearch] = useState(false)






  const handleChange = (event) => {
    console.log(event);
    setLng(event);
    i18n.changeLanguage(event.value);
    localStorage.setItem("lng", event.value);
  };

  useEffect(() => {
    let defaultLng = localStorage.getItem("lng");
    if (defaultLng) {
      let defaultLanguageValue = options.filter(
        (elm) => elm.value === defaultLng
      )[0];
      setLng(defaultLanguageValue);
      i18n.changeLanguage(defaultLanguageValue.value);
    }
  }, []);

  const options = [
    { value: "es", label: "ES" },
    { value: "en", label: "EN" },
    { value: "fr", label: "FR" },
    { value: "ar", label: "AR" },

  ];

  const navItems = [
    {
      title: t("about.title"),
      items: [
        {
          name: t("about.nav.item0"),
          path: "/about-us"
        },
        {
          name: t("about.nav.item1"),
          path: "/our-history"

        },
        {
          name: t("about.nav.item2"),
          path: "/basic-figures"

        },
      ],
    },
    {
      title: t("solutions.title"),
      items: [
        {
          name: t("solutions.nav.item0"),
          path: "/corporate-solutions"

        },
        {
          name: t("solutions.nav.item1"),
          path: "/solutions-for-individuals"

        },
      ],
    },
    {
      title: t("brand.title"),
      isDirectLink: true,
      path: "/brands"
    },
    {
      title: t("innovation.title"),
      isDirectLink: true,
      path: "/innovation"


    },
    {
      title: t("findUs.title"),
      isDirectLink: true,
      path: "/find-us"


    },
  ];
  const openMenu = (i) => {
    setMenu(i)
  }
  const handleChanges = (e) => {
    setSearch(e.target.value)
  }

  const submitSearch = () => {
    window.location.href = `https://www.mapfre-asistencia.com/buscar/?query=${search}`
  }
  return (
    <div className="Header">
      <div className="headerResponsive" style={{ left: isResponsiveOpen && 0, right: isResponsiveOpen && 0 }}>
        <div className="topHeader">
          <a href="/">
            <img className="logo" src="images/logoWhite.png" alt="" />

          </a>
          <div className="topHeader-rightPart-menuIcon" onClick={() => setResponsiveOpen(false)}>
            <img style={{ objectFit: "contain", width: "1.2rem", marginRight: "0.5rem" }} src="images/icons/close.PNG" alt="" />

          </div>
        </div>

        <div className="searchContainer">
          <input type="text" placeholder="¿Qué necesitas?" onChange={handleChanges} value={search} />
          <SearchIcon onClick={submitSearch} />

        </div>
        <div className="responsiveNavLinks">
          {navItems.map((elm, i) =>
            elm.isDirectLink ?
              <a href={`/#${elm.path}`}>{elm.title}</a> :
              <>
                <div className="responsiveNavLink" onClick={() => setCollapseIndex(i === collapseIndex ? null : i)}>
                  <p>{elm.title}</p>
                  {<KeyboardArrowDownIcon className={i === collapseIndex ? "selectedCollapse" : ""} />}
                </div>
                <Collapse in={i === collapseIndex} >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {elm.items.map(item => (
                      <a className="smallLink" href={`/#${item.path}`}  >{item.name}</a>
                    ))}
                  </div>
                </Collapse>
              </>
          )}
        </div>
        <div className="responsiveNavLink mapfre">
          <a href="https://www.mapfre.com/" target="_blank">
            mapfre.com
          </a>
          <EastIcon />
        </div>
        <div style={{ marginTop: "1rem" }}  >
          <Select
            className="headerLanguageSelect"
            isSearchable={false}
            // menuIsOpen = {true}
            styles={style}
            onChange={handleChange}
            value={lng}
            options={options}
          />
        </div>
      </div>


      <div className="headerContainer">
        <div className="topHeader">
          <a href="/">
            <img className="logo" src="images/logoWhite.png" alt="" />

          </a>

          <div className="topHeader-rightPart-menuIcon" onClick={() => setResponsiveOpen(true)}>
            <img src="images/icons/menuSearch.PNG" alt="" />
          </div>
          <div className="topHeader-rightPart" style={{ overflow: isSearchable && "visible" }}>
            <div className="navLink">
              <a href="https://www.mapfre.com/" target="_blank">
                mapfre.com
              </a>
              <EastIcon />
            </div>
            <button className="headerSearchButton" onClick={() => toggleSearch(true)}>
              <SearchIcon onClick={() => toggleSearch(true)} />
            </button>
            <div style={{ paddingLeft: "24px" }}>
              <Select
                className="headerLanguageSelect"
                isSearchable={false}
                // menuIsOpen = {true}
                styles={style}
                onChange={handleChange}
                value={lng}
                options={options}
              />
            </div>


          </div>

        </div>
        <div className="bottomHeader">
          {navItems.map((elm, i) => (
            <div className="navLink">

              {elm.isDirectLink ? <a href={`/#${elm.path}`} >
                {elm.title}
              </a> :
                <p onClick={() => openMenu(i)}>{elm.title}</p>}
            </div>
          ))}

          {isMenuOpen !== null ? <ListMenu handleClose={() => setMenu(null)} options={navItems[isMenuOpen]} /> : null}
        </div>
      </div>
      <div className="searchContainer withBorder" style={{ top: isSearchable && "1rem" }}>
        <input type="text" placeholder="¿Qué necesitas?" onChange={handleChanges} value={search} />
        <SearchIcon onClick={submitSearch} />
        <span onClick={() => toggleSearch(false)} >x</span>
      </div>
    </div >
  );
}

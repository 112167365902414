import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./brands.css"
// import OurBrands from "../../components/ourBrands/OurBrands";

export default function Brands() {
  const { t } = useTranslation();

  const data = [
    {img :"brands/ctama.png" , path : "https://www.ctama.com.tn/"},
    {img :"brands/mae.png" , path : "https://mae.tn/"},
    {img :"brands/mapfre.png" , path : "https://www.mapfre-asistencia.com/"},
    {img :"brands/bh.png" , path : "https://bh-assurance.com/"},
    {img :"brands/carte.png" , path : "https://www.carte.com.tn/"},
    {img :"brands/comar.png" , path : "https://www.comar.tn/"},
    {img :"brands/maghrebia.png" , path : "https://www.maghrebia.com.tn/"},
    {img :"brands/start.png" , path : "https://www.star.com.tn/index.php/fr/"},

  ]


  return (
    <div className="aboutPage">
      <div className="aboutPageBg brands" >
        <h1>{t("brandsPage.title")}</h1>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("brandsPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("brandsPage.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>

      {/* <div className="separationTitle"  >
        <Trans i18nKey="brandsPage.firstParagraph">
          MAPFRE Asistencia is a{" "}
          <strong>global insurance, reinsurance and services company </strong>{" "}
          founded in Madrid (Spain) in 1989 and operating worldwide. We are
          present directly in <strong> 27 offices </strong>, and have more than
          <strong>
            {" "}
            1,250 corporate partners, with more than 100 million people who
            benefit from our services.
          </strong>
        </Trans>
      </div> */}

      {/* <OurBrands /> */}
      <div className="partners-logos">
          {
            data.map((elm,i) => (
              <a href={elm.path} target="_blank" >
              <div className="partner-content">
                <img src={elm.img} alt={elm.img} />
              </div>
              </a>
            ))
          }

      </div>
    </div>
  );
}

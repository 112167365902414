import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Tabs from "../../components/tabs/Tabs";
import TextWithCheckBox from "../../components/cards/TextWithCheckBox"
export default function Assistance() {
    const { t } = useTranslation();

    let data = {
        [t("brandsAssistance.firstBlock.tab1")]: [
            {
                icon: "/images/icons/home.PNG",
                name: t("solutionsCards.card5.name"),
                description: t("solutionsCards.card5.description"),
                link: "/corporate-solutions/insurance/your-home",
            },
            {
                icon: "/images/icons/road.PNG",

                name: t("solutionsCards.card1.name"),
                name: t("solutionsCards.card1.name"),
                description: t("solutionsCards.card1.description"),
                link: "/corporate-solutions/insurance/insurance-road",
            },
            {
                icon: "/images/icons/life.PNG",


                name: t("solutionsCards.card6.name"),
                description: t("solutionsCards.card6.description"),
                link: "/corporate-solutions/insurance/medical-assistance",
            },
            {
                icon: "/images/icons/travel.PNG",


                name: t("solutionsCards.card2.name"),
                description: t("solutionsCards.card2.description"),
                link: "/corporate-solutions/insurance/your-travel",
            },
            {
                icon: "/images/icons/management.PNG",
                name: t("solutionsCards.card10.name"),
                description: t("solutionsCards.card10.description"),
                link: "/corporate-solutions/insurance/management-claims-home/",
            },
        ],
        [t("brandsAssistance.firstBlock.tab2")]: [
            {
                icon: "/images/icons/road.PNG",

                name: t("solutionsCards.card1.name"),
                name: t("solutionsCards.card1.name"),
                description: t("solutionsCards.card1.description"),
                link: "/corporate-solutions/insurance/insurance-road",
            },
            {
                icon: "/images/icons/travel.PNG",


                name: t("solutionsCards.card2.name"),
                description: t("solutionsCards.card2.description"),
                link: "/corporate-solutions/insurance/your-travel",
            },
        ],
        [t("brandsAssistance.firstBlock.tab3")]: [

            {
                icon: "/images/icons/travel.PNG",


                name: t("solutionsCards.card2.name"),
                description: t("solutionsCards.card2.description"),
                link: "/corporate-solutions/insurance/your-travel",
            },
            {
                icon: "/images/icons/road.PNG",

                name: t("solutionsCards.card1.name"),
                name: t("solutionsCards.card1.name"),
                description: t("solutionsCards.card1.description"),
                link: "/corporate-solutions/insurance/insurance-road",
            },
        ],
        [t("brandsAssistance.firstBlock.tab4")]: [
            {
                icon: "/images/icons/travel.PNG",


                name: t("solutionsCards.card2.name"),
                description: t("solutionsCards.card2.description"),
                link: "/corporate-solutions/insurance/your-travel",
            },
            {
                icon: "/images/icons/home.PNG",
                name: t("solutionsCards.card5.name"),
                description: t("solutionsCards.card5.description"),
                link: "/corporate-solutions/insurance/your-home",
            },
            {
                icon: "/images/icons/road.PNG",

                name: t("solutionsCards.card1.name"),
                name: t("solutionsCards.card1.name"),
                description: t("solutionsCards.card1.description"),
                link: "/corporate-solutions/insurance/insurance-road",
            },
            {
                icon: "/images/icons/life.PNG",

                name: t("solutionsCards.card6.name"),
                description: t("solutionsCards.card6.description"),
                link: "/corporate-solutions/insurance/medical-assistance",
            },

        ],
        // Travel: [
        //     {
        //         icon: "/images/icons/travel.PNG",


        //         name: t("solutionsCards.card2.name"),
        //         description: t("solutionsCards.card2.description"),
        //         link: "/corporate-solutions/insurance/your-travel",
        //     },
        // ],
    };

    return (
        <div className="aboutPage">
            <div className="aboutPageBg assistance">
                <div className="left">
                    <h1>{t("brandsAssistance.title")}</h1>
                    <h3>{t("brandsAssistance.secondTitle")}</h3>
                </div>
                <div className="right">
                    <TextWithCheckBox text={t("brandsAssistance.thirdTitle")} />
                    <TextWithCheckBox text={t("brandsAssistance.forthTitle")} />

                </div>

            </div>
            <div className="breadCrumbsContainer">
                <BasicBreadcrumbs
                    options={[
                        {
                            type: "link",
                            pathname: t("brandsAssistance.breadCrumbs.firstPathName"),
                            to: "/",
                        },
                        {
                            pathname: t("brandsAssistance.breadCrumbs.secondPathName"),
                            type: "link",
                            to: "/brands",
                        },
                        {
                            pathname: t("brandsAssistance.breadCrumbs.thirdPathName"),
                            type: "static",
                        },
                    ]}
                />
            </div>

            <div className="separationTitle">
                <Trans i18nKey="brandsAssistance.firstParagraph"></Trans>
            </div>
            <div className="block">
                <h1 className="underLinedTitle">
                    {t("brandsAssistance.firstBlock.title")}
                </h1>
                <Tabs data={data} />
            </div>

        </div>
    );
}

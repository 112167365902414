import React from "react";
import { useTranslation , Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import CheckedItems from "../../components/checkedItems/CheckedItems";



const ClaimManagements = () => {
  const { t } = useTranslation();
    const data = [
        {
            title : t("claimManagementPage.firstBlock.smallTitle"), 
            description : t("claimManagementPage.firstBlock.description")
        },
    ]
  return (
    <div className="aboutPage">
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("claimManagementPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("claimManagementPage.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>
      <h1 className="bigTitle">{t("claimManagementPage.title")}</h1>
      <h1 className="bigTitle" style={{  fontSize:"27px" ,  color: "var(--softGrey)",borderBottom : "none", fontFamily:"DM-SANS-regular"}}>{t("claimManagementPage.secondTitle")}</h1>

      <div className="block">
        <h1 className="underLinedTitle">
          {t("claimManagementPage.firstBlock.title")}
        </h1>
        {/* <Tabs data={data}  /> */}
        <CheckedItems data = {data} />
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("insuranceIndustryPage.secondBlock.title")}
        </h1>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p1"></Trans>
        </p>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p2"></Trans>
        </p>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p3"></Trans>
        </p>
      </div>
    </div>
  );
};

export default ClaimManagements;

import React from "react";
import "./aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";

export default function AboutPage() {
  const { t } = useTranslation();


  return (
    <div className="aboutPage">
      <div className="aboutPageBg">
        <h1>{t("aboutPage.title")}</h1>
        <h3>{t("aboutPage.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("aboutPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
                pathname: t("aboutPage.breadCrumbs.lastPathName"),
                type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="aboutPage.firstParagraph">
          MAPFRE Asistencia is a{" "}
          <strong>global insurance, reinsurance and services company </strong>{" "}
          founded in Madrid (Spain) in 1989 and operating worldwide. We are
          present directly in <strong> 27 offices </strong>, and have more than
          <strong>
            {" "}
            1,250 corporate partners, with more than 100 million people who
            benefit from our services.
          </strong>
        </Trans>
      </div>
      <div className="block">
        <h1 className="underLinedTitle">{t("aboutPage.firstBlock.title")}</h1>
        <p>
          <Trans i18nKey="aboutPage.firstBlock.p1">
            We offer products that increase our partners’ profitability and
            improve people’s quality of life. We are specialised in global
            Assistance, Vehicle Protection and Lifestyle solutions:
          </Trans>
        </p>
        <p className="itemList">
          <Trans i18nKey="aboutPage.firstBlock.p2">
            Our <strong> Assistance </strong> network helps us to take care of
            people and property all over the world. We provide assistance for
            unforeseeable incidents by the roadside and in the home, in addition
            to medical and travel assistance and other services aimed at
            improving quality of life.
          </Trans>
        </p>
        <p className="itemList">
          <Trans i18nKey="aboutPage.firstBlock.p3">
            We protect people’s investments in vehicles, offering{" "}
            <strong>warranty programmes </strong>, extended warranty, loan
            protection, PPI, GAP and <strong>other vehicle coverage .</strong>
          </Trans>
        </p>
        <p className="itemList">
          <Trans i18nKey="aboutPage.firstBlock.p4">
            Our <strong> Lifestyle </strong> products offer peace of mind by
            protecting daily items such as home appliances, computers, mobile
            phones or devices, and credit cards.
          </Trans>
        </p>

        <p>
          <Trans i18nKey="aboutPage.firstBlock.p5">
            We are leaders in providing solutions for the insurance sector,
            including specific solutions for brokers, as well as the financial,
            automotive industry, travel and tourism sectors. We also design
            custom products for companies in other sectors and for large groups.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="aboutPage.firstBlock.p6">
            Some of our products and services are available for individuals to
            purchase directly.
          </Trans>
        </p>
      </div>

      <div className="block">
        <h1 className="underLinedTitle">{t("aboutPage.secondBlock.title")}</h1>
        <p>{t("aboutPage.secondBlock.p1")}</p>
      </div>
    </div>
  );
}

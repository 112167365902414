import React from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

export default function CopyRight() {
    const { t } = useTranslation();


    return (
        <div className="aboutPage">
            <div className="block">
                <h1 className="underLinedTitle">{t("copyRight.title")}</h1>
                <p>
                    <Trans i18nKey="copyRight.firstParagraph">
                    </Trans>
                </p>
                <p >
                    <Trans i18nKey="copyRight.secondParagraph">
                    </Trans>
                </p>
                <p className="itemList">
                    <Trans i18nKey="copyRight.li1">

                    </Trans>
                </p>
                <p className="itemList">
                    <Trans i18nKey="copyRight.li2">

                    </Trans>
                </p>

                <p className="itemList">
                    <Trans i18nKey="copyRight.li3">

                    </Trans>
                </p>
                <p className="itemList">
                    <Trans i18nKey="copyRight.li4">
                    </Trans>
                </p>
                <p className="itemList">
                    <Trans i18nKey="copyRight.li5">
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey="copyRight.li6">
                    </Trans>
                </p>
                <p >
                    <Trans i18nKey="copyRight.lastParagraph">
                    </Trans>
                </p>
            </div>

        </div>
    );
}

import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./solutions.css";
import Solutions from "../../components/solutions/Solutions";
import OurBrands from "../../components/ourBrands/OurBrands";

export default function Coroporate() {
  const { t } = useTranslation();

  return (
    <div className="aboutPage">
      <div className="aboutPageBg corporate">
        <h1>{t("corporate.title")}</h1>
        <h3>{t("corporate.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("corporate.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("corporate.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="corporate.firstParagraph">
          MAPFRE Asistencia is a{" "}
          <strong>global insurance, reinsurance and services company </strong>{" "}
          founded in Madrid (Spain) in 1989 and operating worldwide. We are
          present directly in <strong> 27 offices </strong>, and have more than
          <strong>
            {" "}
            1,250 corporate partners, with more than 100 million people who
            benefit from our services.
          </strong>
        </Trans>
      </div>
      <Solutions />

      <div className="block andAlso">
        <div className="content">
          <h3>{t("corporate.secondBlock.title")}</h3>
          <p>{t("corporate.secondBlock.description")}</p>
        </div>
        <div className="imageContainer">
            <img src="images/solutions/andAlso.webp" alt="" />
        </div>
      </div>
      <OurBrands />
    </div>
  );
}

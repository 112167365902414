import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./insurances.css"
import CheckedItems from "../../components/checkedItems/CheckedItems";
import TextWithCheckBox from "../../components/cards/TextWithCheckBox";

export default function Phone() {
  const { t } = useTranslation();


  return (
    <div className="aboutPage">
      <div className="aboutPageBg insuranceIndustry phone">
        <h1>{t("phoneAssurance.title")}</h1>
        <h3>{t("phoneAssurance.smalmTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("car.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("car.breadCrumbs.secondPathName"),
              type: "link",
              to: "/corporate-solutions",
            },
            
            {
                pathname: t("phoneAssurance.breadCrumbs.thirdPathName"),
              },
           
          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="phoneAssurance.description"></Trans>
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("phoneAssurance.firstTitle")}
        </h1>
          <TextWithCheckBox text = {t("phoneAssurance.condition1")} />
          <h1 className="underLinedTitle">
          {t("phoneAssurance.secondTitle")}
        </h1>
        <TextWithCheckBox text = {t("phoneAssurance.condition2")} />
        <TextWithCheckBox text = {t("phoneAssurance.condition3")} />
        <TextWithCheckBox text = {t("phoneAssurance.condition4")} />

      </div>

    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/outline'

export default function Solutions() {
  const { t } = useTranslation();

  const solutionsData = [
    {
      name: t("corporate.firstBlock.item0.title"),
      description: t("corporate.firstBlock.item0.description"),
      icon: "images/icons/industry.PNG",
      link : "/corporate-solutions/insurance"
    },
    {
      name: t("corporate.firstBlock.item1.title"),
      description: t("corporate.firstBlock.item1.description"),
      icon: "images/icons/car.PNG",
      link : "/corporate-solutions/vehicle"

    },
    {
      name: t("corporate.firstBlock.item2.title"),
      description: t("corporate.firstBlock.item2.description"),
      icon: "images/icons/flight.PNG",
      link : "/corporate-solutions/travel-tourism"

    },
    {
      name: t("corporate.firstBlock.item3.title"),
      description: t("corporate.firstBlock.item3.description"),
      icon: "images/icons/finance.PNG",
      link : "/corporate-solutions/financial-industry"
    },
    //
    {
      name: t("corporate.firstBlock.item4.title"),
      description: t("corporate.firstBlock.item4.description"),
      icon: "images/icons/phone.png",
      link : "/corporate-solutions/phone-warranty"
    },
    {
      name: t("corporate.firstBlock.item5.title"),
      description: t("corporate.firstBlock.item5.description"),
      icon: "images/icons/machine.png",
      link : "/corporate-solutions/Brown-and-White-warranty"
    },
  ];

  return (
    <div className="block">
      <h1 className="underLinedTitle">{t("corporate.firstBlock.title")}</h1>
      <div className="solutionsCards">
        {
            solutionsData.map(elm=> (
                <div className="solutionCard">
                    <img src={elm.icon} alt={elm.name} />
                    <h3>{elm.name}</h3>
                    <p style={{lineHeight : "initial" }}>{elm.description}</p>
                    <Link to={elm.link} > {t("corporate.firstBlock.moreInfo")} <ChevronRightIcon className="w-4 ml-1 cursor-pointer" /></Link>
                </div>
            ))
        }

      </div>
    </div>
  );
}

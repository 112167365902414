import React from 'react'
import "./landingPageLastSection.css"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function LandingPageLastSection() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirect = (path) => {
    navigate(path)
  }
  return (
    <div className='landingPageLastSection'>
      <h1>{t("homePageLastSectionTitle")}</h1>
      <h3>{t("homePageLastSectionParagraph")}</h3>
      <button className="largeButton" onClick={() => redirect("/corporate-solutions")}>{t("firstCardTopButton")}</button>
      <button className="largeButton" onClick={() => redirect("/solutions-for-individuals")}>{t("firstCardBottomButton")}</button>
    </div>
  )
}

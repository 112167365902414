import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';



export default function BasicBreadcrumbs({options = []}) {
  return (
    <div role="presentation" className='BreadCrumbs' >
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        {
            options.map(elm=> 
            elm.type==="link" ? 
            <Link     to={elm.to}>
            {elm.pathname}
          </Link>
            :         
            <Typography>{elm.pathname}</Typography>

        )
        }
      </Breadcrumbs>
    </div>
  );
}
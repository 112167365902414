export const TRANSLATIONS_EN = {
  //navbar
  about: {
    title: "About us",
    nav: {
      item0: "About us",
      item1: "Our history",
      item2: "Basic figures",
    },
  },
  solutions: {
    title: "Solutions",
    nav: {
      item0: "Corporate Solutions",
      item1: "Solutions for individuals",
    },
  },
  brand: {
    title: "Our partners",
  },
  innovation: {
    title: "News",
  },
  findUs: {
    title: "Contact us",
  },

  // page home

  // first cards

  firstCardTitle: "Assistance and services",
  firstCardP:
    "We protect more than 100 million people all over the world guaranteeing what matters most: their own and their families’ peace of mind.",
  firstCardTopButton: "Corporate Solutions",
  firstCardBottomButton: "Individual Solutions",

  secondCardTitle: "Travel Insurance",
  secondCardp:
    "The best travel insurance with the largest\ntraveller assistance network in the world.",

  thirdCardTitle: "Roadside assistance",
  thirdCardp:
    "We protect people’s investments in vehicles, offering warranty programmes, \n loan protection, GAP and other vehicle coverage.",

  // mid cards

  midSectionCardFirstCardTitle: "Corporate Solutions",
  midSectionCardFirstCardp:
    "Products and services that help our partners to increase their business and to generate benefits and protection for their clients.",
  midSectionCardFirstCardLink: "Corporate",

  midSectionCardSecondCardTitle: "Individual Solutions",
  midSectionCardSecondCardp:
    "We are at hand to cater to you and your families’ needs wherever you are in the world. Our mission is to guarantee your peace of mind so you can rest at ease.",
  midSectionCardSecondCardLink: "Individuals",

  midSectionCardThirdCardTitle: "Contact us",
  midSectionCardThirdCardp:
    "Direct presence in 33 countries and five continents, guaranteeing operational excellence anywhere in the world.",
  midSectionCardThirdCardLink: "Contact us",
  //home page last section

  homePageLastSectionTitle: "Lifestyle protection",
  homePageLastSectionParagraph:
    "Electronic device and electrical appliance warranties. \nLoan and purchase protection, and other insurance  peace of mind.",

  //general ,

  moreInfo: "More info",

  //footer
  footer: {
    title: "More information",
    subTitleOne: "Copyright Afrique assistance",
    subTitleTwo: "Privacy policy",
    subTitleThree: "Cookies policy",
    subTitleFour: "Configure cookies",

    copyRight: "Afrique assistance © 2022 All rights reserved",
  },
  ourHistoryPage: {
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Our history",
    },

    title: "Our history",
  },

  figures: {
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Basic figures",
    },
    title: "Main Figures",
  },
  aboutPage: {
    title: "MAPFRE Asistencia",
    secondTitle: "A global insurance, reinsurance \n and services company",
    firstParagraph: ` MAPFRE Asistencia is a <strong >global insurance, reinsurance and services
    company </strong> founded in Madrid (Spain) in 1989 and operating worldwide. We
    are present directly in <strong > 27 offices ,</strong> and have more than<strong > 1,300 corporate
    partners, with more than 100 million people who benefit from our
    services.</strong>`,

    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "About us",
    },

    firstBlock: {
      title: "MAPFRE Asistencia",
      p1: `We offer products that increase our partners’ profitability and improve people’s quality of life. We are specialised in global Assistance, Vehicle Protection and Lifestyle solutions:`,
      p2: `Our <strong>Assistance</strong> network helps us to take care of people and property all over the world. We provide assistance for unforeseeable incidents by the roadside and in the home, in addition to medical and travel assistance and other services aimed at improving quality of life.`,
      p3: `We protect people’s investments in vehicles, offering   <strong>warranty programmes</strong>, extended warranty, loan protection, PPI, GAP and  <strong>other vehicle coverage .</strong>  `,
      p4: `Our <strong>Lifestyle</strong> products offer peace of mind by protecting daily items such as home appliances, computers, mobile phones or devices, and credit cards.`,
      p5: `We are leaders in providing solutions for the insurance sector, including specific solutions for brokers, as well as the financial, automotive industry, travel and tourism sectors. We also design custom products for companies in other sectors and for large groups.`,
      p6: `Some of our products and services are available for individuals to purchase directly. `,
    },
    secondBlock: {
      title: "Awards and Certifications",
      p1: "Our excellence has been rewarded among others with the ITIJ 2005 Award, certifying us as the Best International Assistance Company, and the ITIJ 2011 and 2013 Awards for Best Insurance Company. We also have ISO 9001 international quality certification.",
    },
  },

  innovationPage: {
    title: "News",
    secondTitle: "An essential feature of MAPFRE Asistencia",
    firstParagraph:
      "“The drive to innovate is inseparable from all our company’s facets and processes and leads us to building teams which, like a laboratory, run at full throttle, with specialists ready to take charge of projects in times that would be impossible for anyone else, with people capable of extracting more synergies faster than most, and with work teams that put forward creative solutions.”",
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "News",
    },
  },

  brandsPage: {
    title: "Our partners",
    secondTitle: "MAPFRE Assistance and MAPFRE Warranty",
    firstParagraph:
      "In any case our products and services extend and complement our partners’ commercial offer and can be marketed and distributed under their own brand.",
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Our partners",
    },
  },

  corporate: {
    title: "Corporate \nSolutions",
    secondTitle: "More than 30 years increasing \n our partners’ profitability",
    firstParagraph: `We offer a range of products and services for protecting people and property covering ever-increasing needs. Our partners’ <strong> incorporation or distribution </strong> of these services, products and covers helps them to <strong> grow their business, improve their brand image
    and foster client loyalty. </strong>`,
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Corporate Solutions",
    },
    firstBlock: {
      title: "Solutions",
      item0: {
        title: "Solutions for the Insurance Industry",
        description: `We have our own assistance and service provision network tailored to portfolios of insureds on all 5 continents.
      Our underwriters also offer insurance coverage for vehicle and lifestyle protection.`,
      },
      item1: {
        title: "Solutions for the Automotive Industry",
        description: `We are specialised in designing and supporting the marketing and distribution of mechanical warranty and protection programmes for new and used vehicles.`,
      },
      item2: {
        title: "Solutions for the Travel and Tourism Industry",
        description: `We offer travel insurance and medical assistance programmes for offline or online distribution.Awarded in 2011 and 2013 with the ITIJ Prize for the Best Travel Insurance Company and Affiliate Member of the World Tourism Organisation (WTO).`,
      },
      item3: {
        title: "Solutions for the Financial Industry",
        description: `We develop programmed aimed at increasing and activating financial products.We also provide insurance cover for credit cards and purchases in instalments or with financing.`,
      },
      item4: {
        title: "Mobile phone warranty",
        description: `The Smartphone Accidental Breakage Guarantee is a service that covers and
        bears the cost of repairing or replacing the device in the event of
        Damage caused by falls or impacts`,
      },
      item5: {
        title: "Brown & White warranty",
        description: `The Brown White Warranty Extension is a service that covers and supports
        bears the cost of repairing or replacing the device in the event of a breakdown
        occurred after the expiration of the manufacturer's warranty`,
      },

      moreInfo: "More Information",
    },
    secondBlock: {
      title: "And also…",
      description:
        "we design customised products and programmes for companies and groups to satisfy their clients’ needs and interests.",
    },
  },
  ourBrand: {
    title: "Our partners",
    knowThem: "Know them",
    firstCard: {
      title: "MAPFRE Assistance",
      description: "Comprehensive assistance solutions anywhere in the world.",
    },
    secCard: {
      title: "MAPFRE Warranty",
      description: "Warranty solutions and vehicle and lifestyle protection.",
    },
  },
  individual: {
    title: "Solutions for \nindividuals",
    secondTitle: "Designed to protect you and yours",
    firstParagraph: `Our only objective at MAPFRE Asistencia is to guarantee the peace of mind of you and yours wherever you are. That is why we are constantly evolving, developing solutions adapted to people’s day to day. So that all you need to worry about is enjoying yourself without any risk and with the full protection of the best company.`,
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Solutions for individuals",
    },
    firstBlock: {
      title: "Solutions tailored to your needs",
      item0: {
        title: "Travel Insurance",
        subItem1:
          "Travel at ease with the safety of knowing that you have the backing of a major company.",
        subItem2:
          "Have you ever lost your luggage on a flight? Have you ever had to cancel a trip at the last minute? If you have any problem or need medical assistance during your journey, do you know where to turn to?",
        subItem3:
          "Guarantee your own peace of mind and that of your fellow travellers with the best travel insurance available on the market.",
        link1: "Segurviaje.com",
      },
      item1: {
        title: "Protection for your vehicle",
        subItem1:
          "Protects your vehicle against mechanical, electrical or electronic failures beyond the official warranty period.",
        subItem2:
          "What happens if your car breaks down and is not covered by the manufacturer’s warranty? What if it has an accident?",
        subItem3:
          "Protect yourself against these and other events that can give rise to high unforeseeable expenses.",
        link1: "More information Italy",
        link2: "More information Turkey",
      },
      item2: {
        title: "Lifestyle Protection",
        subItem1:
          "Solutions to protect everything that surrounds you in your day to day.",
        subItem2:
          "Protect your mobile phone, your home appliances and all other devices you use daily against faults.",
        subItem3:
          "Protect your lifestyle also against personal situations that could affect you.",
      },
    },
  },

  solutionsCards: {
    card1: {
      name: "Your road. Our world",
      description: `Roadside Assistance\n
        Complementary Roadside Services`,
    },
    card2: {
      name: "Your travel. Our world",
      description: `Travel Insurance\n
        Travel Cancellation Insurance`,
    },
    card3: {
      name: "Your vehicle. Our world",
      description: `Mechanical Warranty for Vehicles\n
        Repair Assist\n
        GAP`,
    },
    card4: {
      name: "Your lifestyle. Our world",
      description: `Credit Card Protection.\n
      Loan Protection.\n
      Bill Payment Protection.\n
      Income Protection.\n
      Devices Protection.`,
    },
    card5: {
      name: "Your home. Our world",
      description: `Home Assistance\n
      Complementary Home Services`,
    },
    card6: {
      name: "Your life. Our world",
      description: `Personal Assistance`,
    },
    // Programmes desgned for
    card7: {
      name: "Clients Individuals and Companies",
      description: `<strong>Individuals</strong>\n\n  Premium \n\n  Standard\n \n<strong>Companies</strong> \n\n Large \n\n Medium \n\n Small `,
    },
    card8: {
      name: "Products",
      description: `Current Accounts \n \n Savings Accounts \n \n Debit Cards \n \n Credit Cards \n \n Mortgage Loans \n\n Consumer Credit \n \n  Investment Plans \n  \n  Retirement Plans`,
    },
    card9: {
      name: "Distribution channels",
      description: `Offices \n \n ATMs \n \n Online \n \n Telemarketing \n \n Call Center \n \n Direct Mail \n \n Email \n \n Inserts \n \n Social Media`,
    },
    card10: {
      name: "Claims Management",
      description: `Claims Management`,
    },
  },
  // Solutions for the Insurance Industry
  insuranceIndustryPage: {
    title: "Insurance Industry",
    secondTitle:
      "More than 30 years increasing insurance \ncompanies’ profitability",
    firstParagraph: `<strong> Our proposition for the insurance market </strong> \n We are specialists in assistance  and in designing and providing <strong>customised services for insurers and their portfolios of insureds.</strong> \nWe have our own assistance network on all 5 continents. \nAs underwriters we offer insurance companies our experience in designing and <strong>marketing of coverage such as mechanical warranty for vehicles,</strong> such as mechanical warranty for vehicles, home appliance insurance, GAP, PPI, and other risks.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      lastPathName: "Insurance Industry",
    },
    firstBlock: {
      title: "Our Insurances",
      tab1: "Home",
      tab2: "Vehicle",
      tab3: "Life",
      tab4: "Health",
      tab5: "Travel",
    },
    secondBlock: {
      title: "Marketing and Distribution",
      p1: "MAPFRE Asistencia products and services can be added to our partner’s portfolio through <strong> direct inclusion schemes or distributed as an option. </strong>",
      p2: "To help market these products, they are offered in turnkey service-provision mode or in distribution mode underwritten directly by MAPFRE Asistencia or our partner, in this last case under a  <strong> reinsurance scheme. </strong>",
      p3: "All programmes and insurance policies are designed so that they can be offered under our partner’s brand.",
    },
  },
  financialIndustry: {
    title: "Financial Industry",
    secondTitle: "More than 30 years working with the financial sector",
    firstParagraph: `<strong> Our proposition for the Financial market </strong> \n \n We define and run in collaboration with our partners <strong>programmes aimed at increasing the sale and activation of financial products.</strong> \n
      We also provide <strong>insurance cover for credit cards and purchases on credit </strong> , which not only complement and favour the use of financial products, but also constitute a new source of income for our partners.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      lastPathName: "Insurance Industry",
    },
    firstBlock: {
      title: "Our Insurances",
      tab1: "Banks and Financial Institutions",
      tab2: "Programmes desgned for…",
    },
  },
  insuranceVehiculePage: {
    title: "Automotive \nIndustry",
    secondTitle:
      "More than 30 years in collaboration with the \nautomotive sector",
    firstParagraph: `<strong> Our proposition for the insurance market </strong> \n We are specialists in assistance  and in designing and providing <strong>customised services for insurers and their portfolios of insureds.</strong> \nWe have our own assistance network on all 5 continents. \nAs underwriters we offer insurance companies our experience in designing and <strong>marketing of coverage such as mechanical warranty for vehicles,</strong> such as mechanical warranty for vehicles, home appliance insurance, GAP, PPI, and other risks.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      lastPathName: "Automotive Industry",
    },

    firstBlock: {
      title: "Our proposition for the automotive market",
      p1: `We offer a portfolio of complementary vehicle products for marketing and distribution. \n 
      We are specialists  in <strong> mechanical warranty and protection programmes for new and used vehicles.</strong> \n 
      We also offer GAP and PPI solutions for financed vehicle purchases, in addition to other vehicle coverage. \n
      Our solutions are complements perceived to have high added value, helping to favour purchasing decisions and increase sales, as well as providing a new source of revenue for our partners.
      `,
    },
    secondBlock: {
      title: "Business lines for your company",
      p1: `We adapt our solutions to the needs of each client: Vehicle Manufacturers and Importers, Banks, Leasing/Renting companies, Official Dealerships, Independent Distributors, Major Distributors and Affiliated Distributors, Networks of Repair Workshops and Automobile Clubs. `,
    },
    thirdBlock: {
      title: "Our Insurances",
    },
    fourthBlock: {
      title: "Marketing and Distribution",
      p1: `Our complementary products for vehicles are provided turnkey for direct marketing by our partner’s sales network. In order to encourage sales we have our <strong >own training and commercial and marketing support network for workshops and dealerships </strong> all over the world. \n 
     All our products can be marketed under our partner’s brand. `,
    },
    fifthBlock: {
      title: "We are members of the sector",
      p1: `<strong> Our MAPFRE Road Safety and Experimentation Centre  (CESVIMAP) </strong>  has researched everything to do with vehicles since 1983 and has made us a world reference in the sector.`,
    },
  },
  travelTourism: {
    title: "Travel and Tourism \nIndustry",
    secondTitle:
      "More than 30 years collaborating with the travel \nand tourism sector",
    firstParagraph: `<strong> Our proposition for the Travel and Tourism market </strong> \n \n The most comprehensive and competitive <strong>travel insurance and traveller assistance programmes  </strong> available for marketing through our partner’s offline and online channels. \n\nWe have the largest travel assistance network in the world: with more than 31,500 points of assistance on all 5 continents, including hospitals, emergency centres, ambulances, air ambulances, etc.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      lastPathName: "Travel and Tourism Industry",
    },

    firstBlock: {
      title: "Our Insurances",
      p1: `We adapt our solutions to the needs of each client: Transport Companies, Tour Operators, Travel Agencies, Event Organisers, Exhibitions, Conferences and Conventions, Rent a Car Companies, Online Portals, Hotels, Client Relation System and Global Distribution System.
      `,
    },

    secondBlock: {
      title: "Marketing and Distribution",
      p1: `We offer customised travel insurance and services in turnkey mode, ready for marketing and distribution through our partner’s network.
     We have our own training and commercial and marketing support network for <strong >traditional and online travel agencies </strong> all over the world. 
    `,
    },
    thirdBlock: {
      title: "Sector acknowledgements",
      p1: ` We are affiliate members of the World Tourism Organisation (WTO). \n We have received ITIJ 2005, 2011 and 2013 awards for the best travel insurance and assistance company. `,
    },
  },

  //insurances
  homeInsurance: {
    title: `Your home. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `A world in which your insureds can enjoy the best home assistance. The most advanced cover to satisfy client needs and bring them complete peace of mind.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Your home. Our world",
    },
    firstBlock: {
      title: "Coverages",
      coverages: {
        items1: {
          title: "Home Assistance",
          description:
            "Service available 24 hours a day, 365 days a year which quickly resolves any emergency situation in your home thereby avoiding further damage.",
        },
        items2: {
          title: "Complementary Home Services",
          description:
            "Innovative services that complement the home assistance range, and which are positively valued by users. These include contact or provision of professionals, providing the insured access to networks of professionals and partners from any field (technical, repairs, maintenance, removals, etc.) with attractive rates thanks to our provider agreements.",
        },
      },
    },
  },
  lifeStyle: {
    title: `Your lifestyle. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `A world in which MAPFRE Warranty offers a wide range of policies and coverage to protect all manner of objects and credit card use`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Your lifestyle. Our world",
    },
    firstBlock: {
      title: "Products",
      coverages: {
        items1: {
          title: "Credit Card Protection",
          description:
            "Covers purchases acquired with a credit card, compensating the insured in the event that they find the product acquired with the card cheaper elsewhere, robbery at cash dispensing machines, damage or theft of a product acquired using the card and fraudulent use.",
        },
        items2: {
          title: "Loan Protection",
          description:
            "Payment protection exempting the insured from paying the instalments corresponding to a personal loan, the purchase of a vehicle or mortgage loan, in the event of incapacity, illness or involuntary unemployment.",
        },
        items3: {
          title: "Bill Payment Protection",
          description:
            "Coverage insuring against the payment of monthly electricity, water, gas, telephone and internet bills, offering the client complete peace of mind in the event of an accident, illness or involuntary unemployment.",
        },
        items4: {
          title: "Income Protection",
          description:
            "Allows our partners to offer our clients complete peace of mind, protecting their families and lifestyles in situations when work is not possible.",
        },
        items5: {
          title: "Devices Protection",
          description:
            "Coverage that provides our clients with peace of mind, protecting their every day items such as electrical appliances, I.T. equipment, telephones and other mobile devices.",
        },
      },
    },
  },
  road: {
    title: `Your road. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `A world in which MAPFRE Assistance rapidly and effectively provides the solution that best fits its clients. We have been designing roadside assistance programmes for more than two decades, identifying problems and developing services to become leading experts in the sector.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Your road. Our world",
    },
    firstBlock: {
      title: "Coverages",
      coverages: {
        items1: {
          title: "Roadside Assistance",
          description:
            "Covers the assistance needs of the driver, passengers and vehicle in the case of any contingency or difficulty that makes it impossible for the journey to continue following an accident or breakdown.",
        },
        items2: {
          title: "Complementary Roadside Services",
          description:
            "Services that can be customised to the requirements of the insurance company, which are designed to extend their offer, such as replacement vehicle service or contact or provision of professionals, and allow the insured access to networks of partners with attractive rates thanks to our provider agreements.",
        },
      },
    },
  },
  car: {
    title: `Your vehicle. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `A world in which MAPFRE Warranty offers the most complete line of products and coverage for the new and used vehicle markets. New profitable business lines and ad hoc solutions that make us a reference name in the sector.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Your vehicle. Our world",
    },
    firstBlock: {
      title: "Coverages",
      coverages: {
        items1: {
          title: "Mechanical Warranty for Vehicles",
          description:
            "A service which covers mechanical, electrical or electronic breakdowns, which may occur with a new or used vehicle. iWarranty, is also coverage that is especially designed to be included in an insurance policy.",
        },
        items2: {
          title: "Repair Assist",
          description:
            "Guidance over the phone from specialist mechanics who will assess the operations to be performed and the estimated cost of the repairs, and even negotiate with the workshop. If the client so desires, they can propose alternative providers for the job.",
        },
        items3: {
          title: "GAP",
          description:
            "Coverage against the impairment or loan payable on a vehicle when it is written off due to fire, theft or accident.",
        },
      },
    },
  },
  life: {
    title: `Your life. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `A world in which your insureds can count on quality assistance as quickly as possible anywhere in the world.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Your life. Our world",
    },
    firstBlock: {
      title: "Coverages",
      coverages: {
        items1: {
          title: "Personal Assistance",
          description: `Innovative services that complement assistance. Second medical opinion, guidance over the phone (legal, health, nutrition), concierge services, funeral assistance, etc.
          We also provide insureds with access to networks of professionals with attractive rates thanks to our provider agreements.`,
        },
      },
    },
  },
  travel: {
    title: `Your travel. \n Our world`,
    secondTitle: "MAPFRE Assistance",
    firstParagraph: `In this world your insureds will be able to enjoy the pleasure of travel with complete peace of mind whatever their destination, with the guarantee and backup
    of the widest range of travel insurance.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Your travel. Our world",
    },
    firstBlock: {
      title: "Coverages",
      coverages: {
        items1: {
          title: "Travel Insurance",
          description: `Personal protection for the traveller and accompanying persons for every destination and type of travel. Coverage is tailored to the insurers and their clients: from medical assistance to cover potential costs associated to expensive medical treatment that can be required during a trip, to travel cancellation insurance, loss of baggage, or roadside assistance.`,
        },
        items2: {
          title: "Travel Cancellation Insurance",
          description: `Insurance that allows you to plan journeys in advance and that provides peace of mind in the event of journey, holiday, ticket or event cancellations, covering an insured’s non-refundable expenses.`,
        },
      },
    },
  },

  brandsAssistance: {
    title: `MAPFRE Assistance`,
    secondTitle: "World leader in Assistance and Services",
    thirdTitle:
      "We provide assistance against the unforeseeable on the road \nand in the home",
    forthTitle:
      "As well as travel and medical assistance and other services \naimed at improving people’s quality of life",
    firstParagraph: `<strong> MAPFRE Assistance looks after people and property anywhere in the world. </strong> \n\nOur vocation to provide services for people anywhere in the world is the philosophy that determines how we work at MAPFRE Assistance. We have collaborated with our partners for more than 30 years increasing their business volumes, distinguishing features and profitability. We offer innovative products and services that improve people’s quality of life. That is our world.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Our partners",
      thirdPathName: "MAPFRE ASSISTANCE",
    },
    firstBlock: {
      title: "MAPFRE Assistance product lines",
      tab1: "Insurance",
      tab2: "Vehicle",
      tab3: "Travel and Tourism",
      tab4: "Financial",
    },
  },

  brandsWarranty: {
    title: `MAPFRE Warranty`,
    secondTitle:
      "Experts in Warranty, Vehicle and Lifestyle \nProtection solutions",
    thirdTitle:
      "MAPFRE Warranty protects people’s investments \nin vehicles, offering mechanical warranty",
    forthTitle:
      "programmes, loan protection, PPI, GAP and other \nvehicle coverage.",
    firstParagraph: `The Lifestyle Protection range also provides peace of mind protecting every day items \nsuch as electrical appliances, I.T. equipment, telephones and other mobile devices.`,
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Our partners",
      thirdPathName: "Mapfre Warranty",
    },
    firstBlock: {
      title: "MAPFRE Warranty product lines",
      tab1: "Insurance",
      tab2: "Vehicle",
      tab3: "Travel and Tourism",
      tab4: "Financial",
    },
    secondBlock: {
      title: "MAPFRE WARRANTY",
      description:
        "Also offers insurance that protect people against unexpected situations such as unemployment, illness, accident or other unforeseen events that affect the quality and style of life, insuring income or the payment of loans, bills, card fees, etc.",
    },
  },
  findUsPage: {
    breadCrumbs: {
      firstPathName: "Home",
      lastPathName: "Contact us",
    },
    title: "Worldwide presence",
    secondTitle: "Your life. Our world",
  },

  claimManagementPage: {
    breadCrumbs: {
      firstPathName: "Home",
      secondPathName: "Corporate Solutions",
      thirdPathName: "Insurance Industry",
      lastPathName: "Claims Management – Home",
    },
    title: "Claims Management Home",
    secondTitle:
      "A 24-hour service available to insurance companies which builds on MAPFRE Assistance’s excellence in claim management and experience acquired over 30 years across the world.",

    firstBlock: {
      title: "Coverages",
      smallTitle: "Claims Management",
      description:
        "Guarantees the intervention of MAPFRE Assistance professionals in claims covered by the insured´s policy with the scope tailored to the client insurer´s requirements. The insured just has to make a phone call. We take care of the repairs.",
    },
  },
  tunisia: "TUNISIA",

  phoneAssurance: {
    title: "Mobile phone warranty",
    smalmTitle: "Accidental Breakage Guarantee",
    breadCrumbs: {
      thirdPathName: "Mobile phone warranty",
    },
    description: `The Smartphone Accidental Breakage Guarantee is a service that covers and bears the costs of repairing or replacing the device in the event of Damage caused by falls or impacts. \n 
    If your product breaks during the Breakage Guarantee period, it will be repaired or replaced for free! \n
    The Accidental Breakage Guarantee takes effect immediately at the time of purchase of the device and ends on the expiry date (the 1st year from the date of purchase)
    `,
    firstTitle: "In case of Accidental Breakage we offer :",
    condition1: `Repair of the damaged device: includes the cost of labor and the price of parts`,
    secondTitle:
      "Or Replacement with another device of similar characteristics and quality if :",
    condition2: `The covered device is not repairable`,
    condition3: `Repair parts are not available`,
    condition4: `Repair costs cost more than the product itself`,
  },
  machineAssurance: {
    title: "Brown & White Guarantee",
    smalmTitle: "Accidental Breakage Guarantee",

    description: `The Brown & White Warranty Extension is a service that covers and bears the cost of repairing or replacing the appliance in the event of a fault occurring after the expiration of the manufacturer's warranty. \n 
    If your product experiences a covered mechanical, electronic or electrical problem during the Extended Warranty period, it will be repaired or replaced Free of Charge! \n
    The Warranty Extension is taken out on the same day of purchase of the covered device, takes effect on the expiry of the manufacturer's contractual warranty and offers the same guarantees as the manufacturer with the possibility of replacing the product. \n
    In the event of a mechanical, electrical or electronic failure occurring after the expiration of the Manufacturer's Warranty and according to the terms of the warranty, we mean:

    `,
    firstTitle: "Repair of the device includes : ",
    condition1: `labor costs and parts prices`,
    secondTitle:
      "Or Replacement with another device of similar characteristics and quality if :",
    conclusion1: "The Warranty Extension can be 1 or 2 or 3 years",
    conclusion2:
      "The Extended Warranty enhances the product and provides peace of mind from the risk of unforeseen expenses",
  },

  NEWS: "NEWS",
  phone1: "administration",
  phone2: "Call Center - 24/7 Support",
  phone3: "WhatsApp",
  copyRight: {
    title: "Copyright",

    firstParagraph: `Created in 1991 AFRICA ASSISTANCE is a Tunisian-Spanish company certified
    ISO 9001-2008, part of the MAPFRE ASSISTANCE group.`,
    secondParagraph: `AFRICA ASSISTANCE is a leader in the assistance sector in the African continent.
    African continent. It has a complete network of suppliers to meet the daily needs of its
    needs of its customers. The company offers a network of suppliers for :`,
    li1: `A global care: of the vehicle, the driver and all his passengers.
    passengers. `,
    li2: `The services of automobile assistance "tow trucks, vehicle workshops, car rental
    workshops, car rental agencies..."`,
    li3: `A network of suppliers for travel assistance " cabs, airlines, hotels, hospitals
    airlines, hotels, hospitals, doctors, nurses and other health professionals
    ambulances, airplanes, sanitary facilities...",`,
    li4: `A network of suppliers for home assistance "plumbers, glaziers, electricians
    electricians, locksmiths..." `,
    li5: `a network of garage suppliers " Car glass, wreck management, garages
    garages...".`,
    li6: `A network of repairers "technicians, service centers
    household appliances, cell phones..."`,
    lastParagraph: `AFRICA ASSISTANCE provides its services to several corporate clients, including insurance
    insurance companies, financial institutions (banks, leasing companies and
    Leasing companies and dealerships) as well as hypermarkets.`,
  },
};

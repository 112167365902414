import React, { useEffect, useState } from "react";
import "./news.css";
import Marquee from "react-fast-marquee";
import sanityClient from "@sanity/client";
import { useTranslation } from "react-i18next";

export default function News() {
const { t } = useTranslation();
    const [news, setNews] = useState([])
    const client = sanityClient({
        projectId: "0gqe7xtv",
        dataset: "production",
        useCdn: true
    });

    useEffect(() => {
        client
            .fetch('*[_type == "News"]', {})
            .then(res => {
                setNews(res.map(elm => elm.description) || [])
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <div className="newsContainer">
            <div className="newsLabel">
                <h1>{t("NEWS")}</h1>
            </div>
            <Marquee
                loop={0}
                speed={150}
                gradientWidth={0}
                pauseOnHover={true}
                style={{ background: "var(--red)" }}
            >
                {news.map((elm, i) => (
                    <p className="new">
                        {" "}
                        {elm} <span style={{ marginLeft: "1.5rem" }}>|</span>
                    </p>
                ))}
            </Marquee>
        </div>
    );
}

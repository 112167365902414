import React from 'react';
import "./contactCard.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactCard = ({ number, icon , title }) => {
    return (
        <div className="contactCard">
            <div className='contactIcon'>
                <FontAwesomeIcon icon={icon} />

            </div>
            <div>
               <p> {title}</p>
            <h1>{number}</h1>

            </div>
        </div>
    );
}

export default ContactCard;

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./tabs.css";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function Tabs({ data, isMain }) {
  const { t } = useTranslation();

  const [tabs, setTabs] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState("");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    let tabs = Object.keys(data);
    setTabs(tabs);
    setSelectedTab(tabs[0]);
  }, [data]);
  return (
    <div className="tabs">
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            centered
            onChange={handleChange}
            TabIndicatorProps={{ sx: { backgroundColor: "var(--grey)" } }}

          >
            {tabs.map((elm) => (
              <Tab
                sx={{
                  color: "var(--red)",
                  fontFamily: "DM-SANS-medium",
                  textTransform: "initial",
                  fontSize: "18px",

                }}
                variant="scrollable"
                allowScrollButtonsMobile
                label={elm}
                value={elm}
                aria-label="simple tabs example"
              />
            ))}
          </TabList>
        </Box>

        <TabPanel value={selectedTab}>
          <div className="solutionsCards">
            {data &&
              data[selectedTab] &&
              data[selectedTab].map((elm) => (
                <div className="solutionCard">
                  <img src={elm.icon} alt={elm.name} />
                  <h3>{elm.name}</h3>
                  <p
                    style={{
                      margin: "2rem 0 3rem 0",
                      lineHeight: isMain && "initial",
                    }}
                  >
                    {elm.description}
                  </p>
                  {elm.link && (
                    <Link to={elm.link}>
                      {" "}
                      {t("corporate.firstBlock.moreInfo")}{" "}
                      <ChevronRightIcon className="w-4 ml-1 cursor-pointer" />
                    </Link>
                  )}
                </div>
              ))}
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

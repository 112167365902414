import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./insurances.css"
import CheckedItems from "../../components/checkedItems/CheckedItems";

export default function Lifestyle() {
  const { t } = useTranslation();

let data = [
    {
        title : t("lifeStyle.firstBlock.coverages.items1.title"), 
        description : t("lifeStyle.firstBlock.coverages.items1.description")
    },
    {
        title : t("lifeStyle.firstBlock.coverages.items2.title"), 
        description : t("lifeStyle.firstBlock.coverages.items2.description")
    },
    {
        title : t("lifeStyle.firstBlock.coverages.items3.title"), 
        description : t("lifeStyle.firstBlock.coverages.items3.description")
    },
    {
        title : t("lifeStyle.firstBlock.coverages.items4.title"), 
        description : t("lifeStyle.firstBlock.coverages.items4.description")
    },
    {
        title : t("lifeStyle.firstBlock.coverages.items5.title"), 
        description : t("lifeStyle.firstBlock.coverages.items5.description")
    }
]

  return (
    <div className="aboutPage">
      <div className="aboutPageBg insuranceIndustry lifestyle">
        <h1>{t("lifeStyle.title")}</h1>
        <h3>{t("lifeStyle.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("lifeStyle.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("lifeStyle.breadCrumbs.secondPathName"),
              type: "link",
              to: "/corporate-solutions",
            },
            
            {
                pathname: t("lifeStyle.breadCrumbs.thirdPathName"),
                type: "link",
                to: "/corporate-solutions/insurance",
              },
            {
              pathname: t("lifeStyle.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="lifeStyle.firstParagraph"></Trans>
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("lifeStyle.firstBlock.title")}
        </h1>
        {/* <Tabs data={data}  /> */}
        <CheckedItems data = {data} />
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("insuranceIndustryPage.secondBlock.title")}
        </h1>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p1"></Trans>
        </p>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p2"></Trans>
        </p>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p3"></Trans>
        </p>
      </div>
    </div>
  );
}

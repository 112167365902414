import React from "react";
import "../about/aboutPage.css";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import "./insuranceIndustry.css";
import Tabs from "../../components/tabs/Tabs";

export default function InsuranceIndustry() {
  const { t } = useTranslation();

  let data = {
    Home: [
      {
        icon: "/images/icons/home.PNG",
        name: t("solutionsCards.card5.name"),
        description: t("solutionsCards.card5.description"),
        link: "/corporate-solutions/insurance/your-home",
      },
      {
        icon: "/images/icons/lifestyle.PNG",
        name: t("solutionsCards.card4.name"),
        description: t("solutionsCards.card4.description"),
        link: "/corporate-solutions/insurance/your-lifestyle/",
      },
      {
        icon: "/images/icons/management.PNG",
        name: t("solutionsCards.card10.name"),
        description: t("solutionsCards.card10.description"),
        link: "/corporate-solutions/insurance/management-claims-home/",
      },
    ],
    Vehicle: [
      {
        icon: "/images/icons/road.PNG",

        name: t( "solutionsCards.card1.name"),
        name: t("solutionsCards.card1.name"),
        description: t("solutionsCards.card1.description"),
        link: "/corporate-solutions/insurance/insurance-road",
      },
      {
        icon: "/images/icons/management.PNG",

        name: t("solutionsCards.card10.name"),
        description: t("solutionsCards.card10.description"),
        link: "/corporate-solutions/insurance/management-claims-car",
      },
      {
        icon: "/images/icons/carworld.PNG",
        name: t("solutionsCards.card3.name"),
        description: t("solutionsCards.card3.description"),
        link: "/corporate-solutions/insurance/your-vehicle/",
      },
    ],
    Life: [
      {
        icon: "/images/icons/life.PNG",


        name: t("solutionsCards.card6.name"),
        description: t("solutionsCards.card6.description"),
        link: "/corporate-solutions/insurance/medical-assistance",
      },
      {
        icon: "/images/icons/travel.PNG",

     
        name: t("solutionsCards.card2.name"),
        description: t("solutionsCards.card2.description"),
        link: "/corporate-solutions/insurance/your-travel",
      },
    ],
    Health: [
      {
        icon: "/images/icons/life.PNG",

        name: t("solutionsCards.card6.name"),
        description: t("solutionsCards.card6.description"),
        link: "/corporate-solutions/insurance/medical-assistance",
      },
      {
        icon: "/images/icons/travel.PNG",

     
        name: t("solutionsCards.card2.name"),
        description: t("solutionsCards.card2.description"),
        link: "/corporate-solutions/insurance/your-travel",
      },
    ],
    Travel: [
      {
        icon: "/images/icons/travel.PNG",

      
        name: t("solutionsCards.card2.name"),
        description:t("solutionsCards.card2.description"),
        link: "/corporate-solutions/insurance/your-travel",
      },
    ],
  };

  return (
    <div className="aboutPage">
      <div className="aboutPageBg insuranceIndustry">
        <h1>{t("insuranceIndustryPage.title")}</h1>
        <h3>{t("insuranceIndustryPage.secondTitle")}</h3>
      </div>
      <div className="breadCrumbsContainer">
        <BasicBreadcrumbs
          options={[
            {
              type: "link",
              pathname: t("insuranceIndustryPage.breadCrumbs.firstPathName"),
              to: "/",
            },
            {
              pathname: t("insuranceIndustryPage.breadCrumbs.secondPathName"),
              type: "link",
              to: "/corporate-solutions",
            },
            {
              pathname: t("insuranceIndustryPage.breadCrumbs.lastPathName"),
              type: "static",
            },
          ]}
        />
      </div>

      <div className="separationTitle">
        <Trans i18nKey="insuranceIndustryPage.firstParagraph"></Trans>
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("insuranceIndustryPage.firstBlock.title")}
        </h1>
        <Tabs data={data}  />
      </div>
      <div className="block">
        <h1 className="underLinedTitle">
          {t("insuranceIndustryPage.secondBlock.title")}
        </h1>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p1"></Trans>
        </p>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p2"></Trans>
        </p>
        <p>
          <Trans i18nKey="insuranceIndustryPage.secondBlock.p3"></Trans>
        </p>
      </div>
    </div>
  );
}

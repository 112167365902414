import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  HashRouter
} from "react-router-dom";
// import your route components too
import LandingPage from "../pages/landingPage/LandingPage"

import React from 'react'
import AboutPage from "../pages/about/AboutPage";
import OurHistory from "../pages/ourHistory/OurHistory";
import Figures from "../pages/figures/Figures";
import Innovation from "../pages/innovation/Innovation";
import Brands from "../pages/brands/Brands";
import Coroporate from "../pages/solutions/Coroporate";
import Individuals from "../pages/solutions/Individuals";
import InsuranceIndustry from "../pages/insuranceIndustry/InsuranceIndustry";
import Home from "../pages/insurances/Home";
import Lifestyle from "../pages/insurances/LifeStyle";
import Road from "../pages/insurances/Road";
import Car from "../pages/insurances/Car";
import Life from "../pages/insurances/Life";
import Travel from "../pages/insurances/Travel";
import InsuranceVehicule from "../pages/insuranceVehicule/InsuranceVehicule";
import TravelTourismInsurance from "../pages/TravelTourismInsurance/TravelTourismInsurance";
import InsuranceFinancial from "../pages/insuranceFinancial/InsuranceFinancial";
import Warranty from "../pages/brands/Warranty";
import Assistance from "../pages/brands/Assistance";
import FindUs from "../pages/findUs/FindUs";
import ClaimManagements from "../pages/claimManagements/ClaimManagements"
import Phone from "../pages/insurances/Phone";
import BlackAndWhite from "../pages/insurances/BlackAndWhite";
import { useLayoutEffect } from 'react'
import CopyRight from "../pages/copyRight/CopyRight";
import Privacy from "../pages/privacy/Privacy";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}


export default function MainRouter() {
  return (
    <HashRouter >
      <Wrapper>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route index element={<LandingPage />} />
          </Route> */}
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/innovation" element={<Innovation />} />
          <Route path="/our-history" element={<OurHistory />} />
          <Route path="/basic-figures" element={<Figures />} />
          <Route path="/corporate-solutions" element={<Coroporate />} />
          <Route path="/solutions-for-individuals" element={<Individuals />} />
          <Route path="/corporate-solutions/insurance/" element={<InsuranceIndustry />} />
          <Route path="/corporate-solutions/vehicle/" element={<InsuranceVehicule />} />
          <Route path="/corporate-solutions/travel-tourism/" element={<TravelTourismInsurance />} />
          <Route path="/corporate-solutions/financial-industry/" element={<InsuranceFinancial />} />
          <Route path="/corporate-solutions/insurance/your-home" element={<Home />} />
          <Route path="/corporate-solutions/insurance/your-lifestyle" element={<Lifestyle />} />
          <Route path="/corporate-solutions/insurance/insurance-road" element={<Road />} />
          <Route path="/corporate-solutions/insurance/your-vehicle" element={<Car />} />
          <Route path="/corporate-solutions/phone-warranty" element={<Phone />} />
          <Route path="/corporate-solutions/Brown-and-White-warranty" element={<BlackAndWhite />} />
          <Route path="/corporate-solutions/insurance/medical-assistance" element={<Life />} />
          <Route path="/corporate-solutions/insurance/your-travel" element={<Travel />} />
          <Route path="/corporate-solutions/insurance/management-claims-home" element={<ClaimManagements />} />
          <Route path="/brands/assistance" element={<Assistance />} />
          <Route path="/brands/warranty" element={<Warranty />} />
          <Route path="/find-us" element={<FindUs />} />
          <Route path="/copy-right" element={<CopyRight />} />
        </Routes>
      </Wrapper>
    </HashRouter>
  )
}
